import React from 'react'
import cx from 'classnames'
import { string, func, shape, node } from 'prop-types'

// Components
import Icon from '@components/Icon'
import Notifications from '@components/Notifications'
import Avatar from '@components/Avatar'
import Search from '@components/Search'
import FeatureToggle from '@components/FeatureToggle'

import * as models from '@modules/models'

// Styles
import styles from './_.module.scss'

const InternalNav = ({ onBackClick, title }) => (
  <>
    <div
      className={cx('di-f', 'alit-c', 'co-white', styles.backButton)}
      role="button"
      onClick={onBackClick}
    >
      <Icon name="chevron-left" size={20} />{' '}
      <span id="nav-back-accessibility" className="fosi-2">
        Back
      </span>
    </div>
    <div className="co-white">{title}</div>
    <div className={styles.actions} />
  </>
)
InternalNav.propTypes = {
  title: node,
  onBackClick: func
}

InternalNav.defaultProps = {
  title: '',
  internalHeader: <div />,
  onBackClick: () => null
}

const DefaultNav = ({
  onMobileClickGoto,
  myprofileUrl,
  searchUrl,
  notificationsUrl,
  profile
}) => {
  const firstName = profile.given_name
  const lastName = profile.family_name
  const email = profile.nickname
  return (
    <>
      <div>
        <img
          src="/logo-cc.svg"
          alt="A Place For Mom, Community Central Logo"
          width={199}
          className={cx('pay-3', styles.logo)}
        />
      </div>
      <div className="di-f alit-c juco-sb">
        <FeatureToggle name="universalSearch">
          <Search
            className={styles.clickeable}
            onMobileSearchClick={() => onMobileClickGoto(searchUrl)}
            isMobileOnly
          />
        </FeatureToggle>

        <FeatureToggle name="globalNotifications">
          <Notifications
            className={cx(
              'di-f',
              'alit-c',
              'juco-fe',
              'mar-1',
              styles.clickeable
            )}
            count={2}
            onMobileNotificationsClick={() =>
              onMobileClickGoto(notificationsUrl)
            }
            isMobileOnly
          />
        </FeatureToggle>

        <div
          className={cx('di-f', 'alit-c', 'juco-fe', 'mal-1', styles.clickeable)}
          role="button"
          aria-labelledby="nav-myprofile-accessibility"
          onClick={() => onMobileClickGoto(myprofileUrl)}
        >
          <Avatar
            height={20}
            width={20}
            picture={(profile && profile.picture) || ''}
            firstName={firstName || ''}
            lastName={lastName || ''}
            email={email || ''}
            className={cx('bora-round', 'baco-primary-1')}
          />
          <span className="di-n" id="nav-myprofile-accessibility">
            My Profile
          </span>
        </div>
      </div>
    </>
  )
}

DefaultNav.propTypes = {
  myprofileUrl: string,
  searchUrl: string,
  notificationsUrl: string,
  profile: shape(models.Profile),
  onMobileClickGoto: func
}

DefaultNav.defaultProps = {
  myprofileUrl: '',
  searchUrl: '',
  notificationsUrl: '',
  profile: {},
  onMobileClickGoto: () => null
}

const Header = {
  InternalNav,
  DefaultNav
}

export default Header
