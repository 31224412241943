import axios from 'axios'

// modules
import Auth from '@modules/auth'

const getDomain = url => {
  const domain = url
    .toString()
    .replace(/^(https|http):\/\//gi, '')
    .split('/')
  return domain.length > 0 ? domain[0] : ''
}

export const signKnownApis = (token, url, globalConfig) => {
  const config = { ...globalConfig }
  const API_URL = getDomain(process.env.GATSBY_PC_API_URL)

  if (url.indexOf(API_URL) !== -1) {
    config.headers = {
      ...globalConfig.headers,
      Authorization: `Bearer ${token}`
    }
  }
  return config
}

export const handleOnValidSubmit = (model, params, cb) => {
  const { urlsFactory, config: globalConfig } = params
  const urls = urlsFactory(model.search)

  const requests = [],
    propNames = []
  Auth.getToken((_, token) => {
    for (let prop in urls) {
      if (urls.hasOwnProperty(prop)) {
        propNames.push(prop)
        const url = urls[prop]
        const config = signKnownApis(token, url, globalConfig)
        requests.push(axios({ ...config, url }))
      }
    }

    axios
      .all(requests)
      .then(
        axios.spread((...rest) => {
          const responses = {}
          for (let [index, res] of rest.entries())
            responses[propNames[index]] = res.data

          cb(null, responses)
        })
      )
      .catch(err => cb(err))
  })
}
