import Tabs, { Tabs as TabsClasses } from './components/Tabs'
import Tab from './components/Tab'

Tabs.Tab = Tab
Tabs.tabContentClass = TabsClasses.tabContentClass
Tabs.activeTabContent = TabsClasses.activeTabContent
Tabs.phoneOnly = TabsClasses.phoneOnly
Tabs.desktopOnly = TabsClasses.desktopOnly

export { TabsClasses as Tabs, Tab }
export default Tabs
