import { Component } from 'react'
import { any, object, string, func } from 'prop-types'

// hoc
import asTogglesConsumer from '@hoc/asTogglesConsumer'

class FeatureToggle extends Component {
  render() {
    const { name, flags, children, off, offAction } = this.props
    const flagByName = flags[name]

    if (flagByName === false && offAction) offAction()

    return flagByName ? children : off
  }
}

FeatureToggle.propTypes = {
  name: string.isRequired,
  off: any,
  flags: object,
  children: any,
  offAction: func
}

FeatureToggle.defaultProps = {
  flags: {},
  off: null,
  children: null,
  offAction: null
}

export default asTogglesConsumer(FeatureToggle)
