import React from 'react'
import { string, number, object, oneOfType } from 'prop-types'
import cx from 'classnames'

const DEFAULT_ICON_NAME = 'default'

export const styleFactory = ({ size, style }) =>
  size ? { fontSize: size, ...style } : { ...style }

export const Icon = ({ className, name, size, style, themeClassNames, ...rest }) => {
  return (
    <i
      className={cx(`icon-${name}`, 'di-f', 'juco-c', 'alit-c', className)}
      style={styleFactory({ size, style })}
      {...rest}
    />
  )
}

Icon.propTypes = {
  className: string,
  name: string,
  size: oneOfType([number, string]),
  style: object,
  themeClassNames: object
}

Icon.defaultProps = {
  className: null,
  name: DEFAULT_ICON_NAME,
  size: 'inherit',
  style: {},
  themeClassNames: {}
}

export default Icon
