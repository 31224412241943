import React from 'react'

import Desktop from './components/Desktop'
import Mobile from './components/Mobile'

// Constants
import {
  menuLinks,
  avatarMenuLinks,
  myprofileUrl,
  searchUrl,
  notificationsUrl
} from './constants'

const Nav = ({ ...rest }) => (
  <>
    <Desktop
      menuLinks={menuLinks}
      avatarMenuLinks={avatarMenuLinks}
      {...rest}
    />
    <Mobile
      menuLinks={menuLinks}
      myprofileUrl={myprofileUrl}
      searchUrl={searchUrl}
      notificationsUrl={notificationsUrl}
      {...rest}
    />
  </>
)

export default Nav
