import { useState, useEffect } from 'react'

// modules
import { IMPERSONATOR_ROLE, USER_ROLES } from '@modules/constants'
import UserRoles from '@modules/userRoles'
import Auth from '@modules/auth'

const useIsImpersonator = () => {
  const [isImpersonator, setIsImpersonator] = useState(false)

  useEffect(() => {
    Auth.clientIsAuth((err, isAuth) => {
      if (isAuth) {
        UserRoles.hasNeededRoles(
          USER_ROLES,
          [IMPERSONATOR_ROLE],
          (err, userHasAccess) => {
            if (err) {
              setIsImpersonator(false)
            } else {
              setIsImpersonator(userHasAccess)
            }
          }
        )
      }
    })
  }, [])

  return isImpersonator
}

export default useIsImpersonator
