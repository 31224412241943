import { createSelector } from 'reselect'
import { fromJS } from 'immutable'

import {
  COUNTER,
  AUTH_STATE,
  PROFILE,
  IS_VIEW_AS_OPEN,
  COLLECTION_REQUEST_STATUS,
  COLLECTION_REQUEST_ERROR,
  COLLECTION,
  RESOURCE_REQUEST_STATUS,
  RESOURCE_REQUEST_ERROR,
  RESOURCE,
  MOBILE_MENU,
  IS_VIEW_AS_SELECTED,
  VIEWING_AS,
  LOCATION,
  PROFILE_REQUEST_STATUS,
  PROFILE_REQUEST_ERROR,
  SYNTHETIC_BREADCRUMB_ITEMS,
  NOTIFICATION_POOL,
  TAB_SUBMIT_BUTTON
} from './constants'

import { REST_API_ENDPOINTS } from '../constants'

const selectAppReducer = () => state => state

export const getCounter = () =>
  createSelector(
    selectAppReducer(),
    state => state.get(COUNTER)
  )

export const getAuth = () =>
  createSelector(
    selectAppReducer(),
    state => state.get(AUTH_STATE)
  )

export const getProfile = () =>
  createSelector(
    selectAppReducer(),
    state => state.get(PROFILE)
  )

export const getProfileRequestStatus = () =>
  createSelector(
    selectAppReducer(),
    state => state.get(PROFILE_REQUEST_STATUS)
  )

export const getProfileRequestError = () =>
  createSelector(
    selectAppReducer(),
    state => state.get(PROFILE_REQUEST_ERROR)
  )

export const getIsViewAsOpen = () =>
  createSelector(
    selectAppReducer(),
    state => state.get(IS_VIEW_AS_OPEN)
  )

export const getIsViewAsSelected = () =>
  createSelector(
    selectAppReducer(),
    state => state.get(IS_VIEW_AS_SELECTED)
  )

export const getViewingAs = () =>
  createSelector(
    selectAppReducer(),
    state => state.get(VIEWING_AS)
  )

// Collections
export const getCollectionRequestStatus = endpoint =>
  createSelector(
    selectAppReducer(),
    state => fromJS(state).getIn([COLLECTION_REQUEST_STATUS, endpoint], null)
  )

export const getCollectionRequestError = endpoint =>
  createSelector(
    selectAppReducer(),
    state => fromJS(state).getIn([COLLECTION_REQUEST_ERROR, endpoint], null)
  )

export const getCollection = endpoint =>
  createSelector(
    selectAppReducer(),
    state => fromJS(state).getIn([COLLECTION, endpoint], [])
  )

export const getReferralsRequestStatus = () =>
  getCollectionRequestStatus(REST_API_ENDPOINTS.referrals)

export const getReferralsRequestError = () =>
  getCollectionRequestError(REST_API_ENDPOINTS.referrals)

export const getReferrals = () => getCollection(REST_API_ENDPOINTS.referrals)

// Resources
export const getResourceRequestStatus = endpoint =>
  createSelector(
    selectAppReducer(),
    state => fromJS(state).getIn([RESOURCE_REQUEST_STATUS, endpoint], null)
  )

export const getResourceRequestError = endpoint =>
  createSelector(
    selectAppReducer(),
    state => fromJS(state).getIn([RESOURCE_REQUEST_ERROR, endpoint], null)
  )

export const getResource = endpoint =>
  createSelector(
    selectAppReducer(),
    state => fromJS(state).getIn([RESOURCE, endpoint], [])
  )

export const getLeadRequestStatus = () =>
  getResourceRequestStatus(REST_API_ENDPOINTS.leads)

export const getLeadRequestError = () =>
  getResourceRequestError(REST_API_ENDPOINTS.leads)

export const getLead = () => getResource(REST_API_ENDPOINTS.leads)

export const getMobileMenu = () =>
  createSelector(
    selectAppReducer(),
    state => state.get(MOBILE_MENU)
  )

export const getLocation = () =>
  createSelector(
    selectAppReducer(),
    state => state.get(LOCATION)
  )

export const getSyntheticBreadcrumbItems = () =>
  createSelector(
    selectAppReducer(),
    state => state.get(SYNTHETIC_BREADCRUMB_ITEMS)
  )

export const getNotificationPool = () =>
  createSelector(
    selectAppReducer(),
    state => state.get(NOTIFICATION_POOL)
  )

export const getTabSubmitButton = () =>
  createSelector(
    selectAppReducer(),
    state => state.get(TAB_SUBMIT_BUTTON)
  )
