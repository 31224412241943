import React from 'react'
import cx from 'classnames'
import { string, shape, number, node } from 'prop-types'

// modules
import datejs from '@modules/datejs'

// styles
import styles from './_.module.scss'

const Referral = ({ date, status, contact, resident, advisor, community, url }) => {
  return (
    <a
      className={cx(
        'di-f',
        'fldi-c',
        'pay-2',
        'pax-4',
        'fosi-2',
        'cu-p',
        styles.leadBox
      )}
      href={url}
    >
      <div className="di-f fldi-r pab-2">
        <div className={cx('di-f', 'fldi-c', styles.dateLeadCommunity)}>
          <span className="co-gray-4 fosi-1">
            {datejs(date).format('MM/DD/YYYY')}
          </span>
          <span className="co-black fowe-b">{contact.firstName} {contact.lastName}</span>
          <div>{community.name}</div>
        </div>
        <div className={cx('di-f', 'juco-fe', styles.status)}>
          <span className="mat-1 pax-2 pay-1 baco-gray-2 co-gray-4">
            {status}
          </span>
        </div>
      </div>
      <div className="di-f juco-sb">
        <div className={cx('di-f', 'fldi-c', styles.residentAdvisor)}>
          <span className="fosi-1 co-gray-3">Resident</span>
          <span>{resident.firstName} {resident.lastName}</span>
        </div>
        <div className={cx('di-f', 'fldi-c', styles.residentAdvisor)}>
          <span className="fosi-1 co-gray-3">Advisor</span>
          <span>{advisor.firstName} {advisor.lastName}</span>
        </div>
      </div>
    </a>
  )
}

Referral.propTypes = {
  date: string,
  status: string,
  contact: shape({ firstName: node, lastName: node }),
  resident: shape({ firstName: node, lastName: node }),
  advisor: shape({ firstName: string, lastName: string }),
  community: shape({ id: number, name: string }),
  url: string
}

Referral.defaultProps = {
  date: '',
  status: '',
  contact: {},
  resident: {},
  advisor: {},
  community: {},
  url: ''
}

export default Referral
