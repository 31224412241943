import React, { Component } from 'react'
import { object, func, string, number } from 'prop-types'
import cx from 'classnames'

// components
import TextField from '@components/TextField'
import Icon from '@components/Icon'
import Form from '@components/Form'
import EventOutsideDetector from '@components/EventOutsideDetector'
import DropdownContentWrapper from '@components/DropdownContentWrapper'
import { ToastNotify } from '@compositions/Toast'

// modules
import { handleOnValidSubmit } from './modules/helpers'

// styles
import styles from './_.module.scss'

// accessors
const STATE_IS_FIRST_LOAD = 'isFirstLoad'
const STATE_IS_LOAD = 'isLoad'
const STATE_DATASETS = 'datasets'
const STATE_ERROR = 'error'
const STATE_IS_PRESENT = 'isPresent'
const STATE_QUERY = 'query'
const STATE_HOLD_ON_SEARCH = 'holdOnSearch'
export default class XHRTextField extends Component {
  state = {
    [STATE_IS_FIRST_LOAD]: true,
    [STATE_DATASETS]: {},
    [STATE_ERROR]: null,
    [STATE_IS_PRESENT]: false,
    [STATE_IS_LOAD]: true,
    [STATE_QUERY]: '',
    [STATE_HOLD_ON_SEARCH]: false
  }

  constructor(props) {
    super(props)
    this.submit = React.createRef()
  }

  componentDidMount() {
    const urlsFactory = this.props.urlsFactory('')

    for (let prop in urlsFactory) {
      if (urlsFactory.hasOwnProperty(prop)) {
        this.setState(state => {
          return { [STATE_DATASETS]: { ...state[STATE_DATASETS], [prop]: [] } }
        })
      }
    }
  }

  render() {
    const {
      presenterFactory,
      presenterStyles,
      presenterClassnames,
      urlsFactory,
      config,
      errorMessage,
      minimumCharactersLength
    } = this.props
    const {
      [STATE_QUERY]: query,
      [STATE_DATASETS]: datasets,
      [STATE_ERROR]: error,
      [STATE_IS_LOAD]: isLoad,
      [STATE_IS_PRESENT]: isPresent,
      [STATE_HOLD_ON_SEARCH]: holdOnSearch
    } = this.state

    const params = {
      query,
      datasets,
      error,
      isLoad
    }

    return (
      <EventOutsideDetector
        onEvent={() => this.setState({ [STATE_IS_PRESENT]: false })}
        className="po-r"
      >
        <Form
          onValid={() => {
            this.tmr && clearTimeout(this.tmr)
            this.tmr = setTimeout(() => {
              if (holdOnSearch) return false
              this.setState({ [STATE_IS_PRESENT]: false })
              this.setState({ [STATE_IS_LOAD]: true })

              if (this.state[STATE_IS_FIRST_LOAD]) {
                this.setState({ [STATE_IS_FIRST_LOAD]: false })
              } else {
                this.submit.current.click()
              }
            }, 1000)
          }}
          onValidSubmit={model => {
            this.setState({ [STATE_IS_PRESENT]: true })

            if (model.search.length) {
              handleOnValidSubmit(
                model,
                { urlsFactory, config },
                (err, responses) => {
                  this.setState({
                    [STATE_IS_LOAD]: false,
                    [STATE_QUERY]: model.search
                  })
                  if (err) {
                    ToastNotify.error(errorMessage)
                    return this.setState({
                      [STATE_ERROR]: err,
                      [STATE_DATASETS]: {}
                    })
                  }
                  this.setState({
                    [STATE_DATASETS]: responses,
                    [STATE_ERROR]: null
                  })
                }
              )
            } else {
              this.setState({ [STATE_IS_LOAD]: false })
              this.setState({ [STATE_DATASETS]: {} })
            }
          }}
        >
          <TextField.WithIcon
            name="search"
            placeholder="Leads, communities"
            icon={<Icon name="search" />}
            autoComplete="off"
            onChange={event => {
              const inputLenght = event.currentTarget.value.length
              if (!inputLenght) this.setState({ [STATE_IS_PRESENT]: false })
              else if (
                minimumCharactersLength &&
                inputLenght < minimumCharactersLength
              )
                this.setState({
                  [STATE_IS_PRESENT]: false,
                  [STATE_HOLD_ON_SEARCH]: true
                })
              else this.setState({ [STATE_HOLD_ON_SEARCH]: false })
            }}
            onFocus={event => {
              const inputLenght = event.currentTarget.value.length
              if (inputLenght) {
                if (
                  !minimumCharactersLength ||
                  inputLenght >= minimumCharactersLength
                ) {
                  this.setState({ [STATE_IS_PRESENT]: true })
                }
              } else this.setState({ [STATE_IS_PRESENT]: false })
            }}
          />
          <button ref={this.submit} type="submit" hidden>
            Submit
          </button>
        </Form>

        {isPresent && (
          <DropdownContentWrapper
            className={cx(presenterClassnames)}
            style={{ ...presenterStyles }}
            cardClassName={styles.card}
            cardStyle={{ overflowY: 'auto' }}
          >
            {presenterFactory(params)}
          </DropdownContentWrapper>
        )}
      </EventOutsideDetector>
    )
  }
}

XHRTextField.propTypes = {
  urlsFactory: func.isRequired,
  config: object,
  presenterFactory: func,
  presenterStyles: object,
  presenterClassnames: string,
  errorMessage: string,
  minimumCharactersLength: number
}

XHRTextField.defaultProps = {
  config: {},
  presenterFactory: <div />,
  presenterStyles: {},
  presenterClassnames: '',
  errorMessage: '',
  minimumCharactersLength: 0
}
