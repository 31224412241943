import React from 'react'
import cx from 'classnames'
import { number, func, bool } from 'prop-types'

// Styles
import styles from './_.module.scss'

import svg from './assets/notifications.svg'

const IconBell = ({ count, isOpen, onClick }) => (
  <div
    className={cx(
      'di-f',
      'alit-c',
      'co-white',
      'po-r',
      'cu-p',
      'pa-1',
      'teal-c',
      'juco-c',
      styles.iconBell
    )}
    onClick={onClick}
    role="button"
    aria-labelledby="notifications-accessibility"
  >
    <div
      className={cx('bora-round', styles.image, { 'baco-primary-2': isOpen })}
    >
      <img
        className="di-b"
        src={svg}
        height={20}
        width={20}
        alt="Notifications"
      />
    </div>
    {count > 0 && (
      <div
        className={cx(
          'po-a',
          'bora-round',
          'baco-orange-3',
          'co-white',
          'fosi-1',
          'di-f',
          'juco-c',
          'alit-c',
          styles.count
        )}
      >
        {count}
      </div>
    )}
    <span
      className="di-n"
      id="notifications-accessibility"
    >{`${count} Notification${count !== 1 && 's'}`}</span>
  </div>
)

IconBell.propTypes = {
  count: number,
  isOpen: bool,
  onClick: func
}

IconBell.defaultProps = {
  count: 0,
  isOpen: false,
  onClick: () => null
}

export default IconBell
