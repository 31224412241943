import React, { useState, useEffect } from 'react'
import cx from 'classnames'

// components
import { ToastNotify } from '@compositions/Toast'
import Avatar from '@components/Avatar'
import MediaObject, { Sidebar, Content } from '@components/MediaObject'
import TextLink from '@components/TextLink'
import Icon from '@components/Icon'

//modules
import Auth from '@modules/auth'
import Cookie from '@modules/cookie'
import Router from '@modules/router'
import { IMPERSONATION_COOKIE_NAME } from '@modules/constants'

import styles from './_.module.scss'

const IMPERSONATION_LINK = '/impersonation'

const ImpersonationStatusBar = () => {
  const [impersonatedUser, setImpersonatedUser] = useState(null)

  useEffect(() => {
    let isImpersonatingCallback = (err, cookie) => {
      if (err) {
        ToastNotify.error(
          'An error unexpected error occurred while impersonating the user'
        )
        setImpersonatedUser(null)
        return Cookie.remove(IMPERSONATION_COOKIE_NAME, null, () => {
          Router.go(IMPERSONATION_LINK)
        })
      }
      setImpersonatedUser(cookie)
    }
    Auth.isoIsImpersonating((err, token) => {
      if (!err && token) {
        Auth.getCookie(IMPERSONATION_COOKIE_NAME, isImpersonatingCallback)
      }
    })
    return () => {
      isImpersonatingCallback = () => null
    }
  }, [])

  function handleOnExit(event) {
    event.preventDefault()
    Cookie.remove(IMPERSONATION_COOKIE_NAME, null, () => {
      ToastNotify.success("Impersonated user's session closed")
      setImpersonatedUser(null)
      Router.go(IMPERSONATION_LINK)
    })
  }

  const { firstName, lastName, email } = impersonatedUser || {}
  return (
    impersonatedUser && (
      <div className="baco-gray-1 pax-4">
        <div
          className={cx(
            'di-f',
            'alit-c',
            'juco-sb',
            'ml-a',
            'mr-a',
            styles.wrapper
          )}
        >
          <MediaObject alignCenter={false} className="alit-c">
            <Sidebar width="auto">
              <Avatar
                size={40}
                className="bora-round boco-primary-3 bowi-2 bost-s"
                picture={''}
                firstName={firstName}
                lastName={lastName}
                email={email}
                iconName="person"
              />
            </Sidebar>
            <Content className="di-f fldi-c">
              <span className="fowe-b fosi-2">
                {firstName} {lastName}
              </span>
              <span className={cx('fosi-1', styles.email)}>{email}</span>
            </Content>
          </MediaObject>

          <span className={cx('di-f', 'fosi-2', styles.links)}>
            <TextLink
              className="di-f fowe-b pa-2 mar-2 alit-c"
              to={IMPERSONATION_LINK}
            >
              <Icon name="search" className="mar-2 co-gray-4" />
              Impersonate New User
            </TextLink>
            <span
              className="di-f cu-p fowe-b pa-2 alit-c"
              onClick={handleOnExit}
            >
              <Icon name="close" className="mar-2 co-gray-4" />
              Exit
            </span>
          </span>
        </div>
      </div>
    )
  )
}

export default ImpersonationStatusBar
