import React from 'react'
import cx from 'classnames'
import { string, node } from 'prop-types'

import MediaObject, { Sidebar, Content } from '@components/MediaObject'
import Avatar from '@components/Avatar'

import styles from './_.module.scss'

const QA_PREFIX = 'qa-notification-card'

const Notifications = ({ picture, content, date }) => {
  return (
    <MediaObject alignCenter={false} className={styles.notification}>
      <Sidebar width={60}>
        <Avatar
          className={`${QA_PREFIX}__output--avatar`}
          size={40}
          picture={picture}
        />
      </Sidebar>
      <Content>
        <div className={cx('di-f', 'fldi-c', 'fosi-2')}>
          <span className={`co-black ${QA_PREFIX}__output--content`}>
            {content}
          </span>
          <span className={`fosi-1 co-gray-4 ${QA_PREFIX}__output--content`}>
            {date}
          </span>
        </div>
      </Content>
    </MediaObject>
  )
}

Notifications.propTypes = {
  picture: string,
  content: node,
  date: string
}

Notifications.defaultProps = {
  picture: '',
  content: null,
  date: ''
}

export default Notifications
