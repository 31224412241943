import React, { Component } from 'react'

import { cssVariables } from '@components/constants'

export default function(WrappedComponent, config = {}) {
  return class extends Component {
    render() {
      const { elevation: elevationFromProps } = this.props // eslint-disable-line react/prop-types
      const { iterationsCount } = cssVariables

      let curedElevation =
        elevationFromProps < 1 || elevationFromProps > iterationsCount
          ? 1
          : elevationFromProps

      return <WrappedComponent {...this.props} elevation={curedElevation} />
    }
  }
}
