import datejs from '../datejs'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

import { MAX_VALUE, MAX_INTEGERS, MAX_DECIMALS } from './constants'

const moneyDefaultOptions = {
  maxValue: MAX_VALUE,
  maxIntegersLength: MAX_INTEGERS,
  maxDecimalsLength: MAX_DECIMALS,
  alwaysInteger: false
}
export default class Formatter {
  static phone(number = '', format = '($1) $2-$3-$4') {
    const numberAsString = `${number}`
    const formatted = numberAsString
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d{3})(\d{4})(\d*)/, format)
      .replace(/\s*$/, '')
      .replace(/\D*$/, '')

    return formatted
  }

  static date(date, format = 'MMM DD, YY') {
    return datejs(date).format(format)
  }

  static time(date, format = 'HH:mm') {
    return datejs(date).format(format)
  }

  static trimSpaces(string = '') {
    return string.replace(/\s/g, '')
  }

  static snakeSpaces(string = '') {
    return string.replace(/\s/g, '-').toLowerCase()
  }
  static ucfirst(string = '') {
    return `${string.charAt(0).toUpperCase()}${string.slice(1)}`
  }

  static stripHtmlTags(text) {
    const regexStripTags = /(<([^>]+)>)/gi
    const regexBreakLine = /(<br([^>]*)>)/gi
    return text.replace(regexBreakLine, '\n').replace(regexStripTags, '')
  }

  static stripBreakLines(text) {
    return text.replace(/(\n)/gi, '<br>').replace(/(\r\n)/gi, '<br>')
  }

  static toDecimal(text, maxDecimals = MAX_DECIMALS) {
    const number = Formatter.number(text)
    if (!number) return ''
    const decimals = parseFloat((number % 1).toFixed(maxDecimals))
    const decimalString = decimals.toString().split('.')[1]
    const length = decimalString ? decimalString.length : 0
    return number.toFixed(length < maxDecimals ? length : maxDecimals)
  }

  static truncateNumber(value, maxValue) {
    const number = Formatter.number(value)
    if (number > maxValue) {
      return maxValue
    }
    return number
  }

  static filterNumericText(text) {
    return text
      .toString()
      .replace(',', '')
      .replace(/[^0-9.]/g, '')
  }

  static number(text) {
    const cleanNumber = parseFloat(Formatter.filterNumericText(text))
    if (isNaN(cleanNumber)) return null
    return cleanNumber
  }

  static money(value, config = {}) {
    const options = Object.assign({}, moneyDefaultOptions, config)
    const {
      fillZero,
      maxValue,
      maxIntegersLength,
      maxDecimalsLength,
      alwaysInteger
    } = options

    let newValue = Formatter.number(value)

    if (!newValue) return ''

    if (maxValue > -1 && maxIntegersLength > -1 && newValue > maxValue) {
      newValue = Formatter.truncateNumber(newValue, maxValue)
    }
    if (maxDecimalsLength > -1) {
      newValue = Formatter.toDecimal(newValue, maxDecimalsLength)
      if (fillZero) {
        newValue = parseFloat(newValue).toFixed(maxDecimalsLength)
      }
    }
    return alwaysInteger
      ? parseInt(newValue.toString())
      : newValue.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  static arrayFill(arr, size, child = false) {
    const length = arr.length
    if (length === size) return arr
    const diff = size - length
    for (let i = 0; i < diff; i++) {
      arr.push(child ? child : i + 1)
    }
  }

  static noteFromAdvisorDemultiplexer(noteFromAdvisorAsString) {
    const hasUpdates = /^UPDATE:/.test(noteFromAdvisorAsString)

    if (!hasUpdates) {
      return {
        note: noteFromAdvisorAsString,
        updates: []
      }
    }

    const updateRegExp = /UPDATE:\s?/
    const updatesFromNote = noteFromAdvisorAsString.split(updateRegExp)
    const lastUpdate = updatesFromNote.pop()
    let note = ''

    if (lastUpdate) {
      const noteRegExp = /(<br\s?\/>){2}?/
      const splittedNoteFromUpdate = lastUpdate.split(noteRegExp)
      updatesFromNote.push(splittedNoteFromUpdate.shift())
      updatesFromNote.shift()
      const regex = new RegExp(/<br\s?\/>/)
      const filterFirstBreakline = (item, index) =>
        index > 0 || !regex.test(item)
      const noFirstBreakline = splittedNoteFromUpdate.filter(
        filterFirstBreakline
      )
      note = splittedNoteFromUpdate.length ? noFirstBreakline.join('') : ''
    }

    let updates = []
    let firstDateFromUpdate = ''
    let dateRegExp = /(\d{1,2}\/){2}\d*\s?-\s?/
    let updateWithoutDate = ''
    let datesFromUpdate = []
    for (let update of updatesFromNote) {
      datesFromUpdate = update.match(dateRegExp) || []
      updateWithoutDate = update.replace(dateRegExp, '')
      firstDateFromUpdate = datesFromUpdate[0]
      if (firstDateFromUpdate) {
        firstDateFromUpdate = firstDateFromUpdate.replace(/(\s|-)/g, '')
      }
      updates.push({ date: firstDateFromUpdate, value: updateWithoutDate })
    }

    return {
      note,
      updates
    }
  }

  static timeAgo(dateTime) {
    TimeAgo.addLocale(en)
    const timeAgo = new TimeAgo('en-US')
    return timeAgo.format(dateTime)
  }

  static getFirstWord(text) {
    return text
      .trim()
      .replace('-', ' ')
      .split(' ')
      .shift()
  }

  static sumChars(str) {
    return str.split('').reduce((acc, curr) => acc + curr.charCodeAt(0), 0)
  }
}
