import React from 'react'
import cx from 'classnames'

// components
import Avatar from '../Avatar'
import { any, string } from 'prop-types'

// hoc
import withSizes from '@hoc/withSizes'

// styles
import styles from './_.module.scss'

const Contained = ({size, ...rest}) => { 
    return (
        <div
            className={cx(
                styles.avatarContained, 
                styles[size],
                'di-f', 'juco-c'
            )}
        >
            <Avatar {...rest} />
        </div>
    )
}

Contained.propTypes = {
    iconName: any.isRequired,
    size: string
}

Contained.defaultProps = {
    size: 'md'
}

export default withSizes(Contained)