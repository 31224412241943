import React from 'react'
import { any, string } from 'prop-types'

// components
import Card from '@components/Card'

const Content = ({ children, className }) => (
  <Card elevation={2} className={className}>
    {children}
  </Card>
)

Content.propTypes = {
  children: any,
  className: string
}

Content.defaultProps = {
  children: null,
  className: ''
}

export default Content
