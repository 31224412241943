import React from 'react'
import { bool, func, string } from 'prop-types'
import SearchIcon from './components/SearchIcon'

const Search = ({ isMobileOnly, onMobileSearchClick, className }) => {
  return isMobileOnly ? (
    <SearchIcon
      onMobileSearchClick={onMobileSearchClick}
      className={className}
    />
  ) : (
    <div>text</div>
  )
}

Search.propTypes = {
  className: string,
  isMobileOnly: bool,
  onMobileSearchClick: func
}

Search.defaultProps = {
  className: '',
  isMobileOnly: false,
  onMobileSearchClick: () => null
}

export default Search
