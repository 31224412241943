import React, { Component } from 'react'
import { THEMES } from '../components/constants'

export default function(WrappedComponent, config = {}) {
  return class extends Component {
    render() {
      const { palette } = this.props // eslint-disable-line react/prop-types
      const colorPalette = palette && THEMES[palette.toLowerCase()] // eslint-disable-line react/prop-types
      return <WrappedComponent {...this.props} palette={colorPalette} />
    }
  }
}
