import React from 'react'
import { node, string, oneOf, bool } from 'prop-types'
import cx from 'classnames'

import { FLOAT_LEFT, FLOAT_RIGHT } from '../../constants'

// Styles
import styles from './_.module.scss'

const MediaObject = ({ children, alignCenter, margin, className, float, ...rest }) => (
  <div
    className={cx(
      'di-f',
      'alit-s',
      !margin ? 'mat-2' : margin,
      !margin ? 'mab-2' : null,
      alignCenter ? 'ml-a mr-a' : '',
      styles.mediaObject,
      className
    )}
    {...rest}
  >
    {children.map((item, key) => {
      const { props, type: Component } = item || {}
      const { children: itemChildren } = props || {}
      return (
        (Component && (
          <Component {...props} float={float} key={key}>
            {itemChildren}
          </Component>
        )) ||
        null
      )
    })}
  </div>
)

MediaObject.propTypes = {
  alignCenter: bool,
  children: node,
  className: string,
  margin: string,
  float: oneOf([FLOAT_LEFT, FLOAT_RIGHT])
}

MediaObject.defaultProps = {
  alignCenter: true,
  margin: '',
  className: '',
  float: FLOAT_LEFT,
  children: null
}

export default MediaObject
