import React from 'react'
import { node } from 'prop-types'

const MessageResult = ({ children }) => {
  return <div className="pax-4 pay-2 fosi-2">{children}</div>
}

MessageResult.propTypes = {
  children: node
}

MessageResult.defaultProps = {
  children: null
}

export default MessageResult
