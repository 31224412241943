import React, { Component } from 'react'
import cx from 'classnames'
import { number, string, bool, func } from 'prop-types'

// components
import IconBell from './components/IconBell'
import Notification from './components/Notification'
import DropdownContentWrapper from '@components/DropdownContentWrapper'
import EventOutsideDetector from '@components/EventOutsideDetector'

// styles
import styles from './_.module.scss'

const STATE_IS_OPEN = 'isOpen'
class Notifications extends Component {
  state = {
    [STATE_IS_OPEN]: false
  }
  handleClick() {
    const { isMobileOnly, onMobileNotificationsClick } = this.props
    if (isMobileOnly) {
      onMobileNotificationsClick()
    } else {
      this.setState({ [STATE_IS_OPEN]: !this.state[STATE_IS_OPEN] })
    }
  }
  render() {
    const { count, isMobileOnly, className } = this.props
    const isOpen = this.state[STATE_IS_OPEN]
    return (
      <EventOutsideDetector
        eventName="mousedown,mousemove"
        onEvent={() => this.setState({ [STATE_IS_OPEN]: false })}
      >
        <div
          className={cx('co-white', 'po-r', styles.notifications, className)}
        >
          <IconBell
            isOpen={isOpen}
            count={count}
            onClick={() => this.handleClick()}
          />
          {!isMobileOnly && (
            <DropdownContentWrapper
              className={cx(styles.panel, 'mat-1', { 'di-n': !isOpen })}
            >
              <h3 className={cx('co-black', 'pa-3')}>Notifications</h3>
              <Notification
                picture=""
                content={
                  <>
                    <b>Holly Barrett</b> scheduled a tour for <b>Evan Gogoro</b>
                  </>
                }
                date="Yesterday, 12:13 PM"
              />
            </DropdownContentWrapper>
          )}
        </div>
      </EventOutsideDetector>
    )
  }
}

Notifications.propTypes = {
  className: string,
  count: number,
  isMobileOnly: bool,
  onMobileNotificationsClick: func
}

Notifications.defaultProps = {
  className: '',
  count: 0,
  isMobileOnly: false,
  onMobileNotificationsClick: () => null
}

export default Notifications
