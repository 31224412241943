import React, { Component } from 'react'
import { any, func, string } from 'prop-types'

// modules
import Dom from '@modules/dom'
import Env from '@modules/env'

export default class Detector extends Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
    this.eventName = props.eventName
    this.eventNames = this.eventName.split(',')
  }

  componentDidMount() {
    Dom.addMultipleEventListeners(
      Env.getDocument(),
      this.eventNames,
      this.handleEvent
    )
  }

  componentWillUnmount() {
    Dom.removeMultipleEventListeners(
      Env.getDocument(),
      this.eventNames,
      this.handleEvent
    )
  }

  handleEvent = ({ target }) => {
    const { onEvent } = this.props
    if (this.ref && this.ref.current && !this.ref.current.contains(target))
      onEvent()
  }

  render() {
    const { children, className } = this.props

    return (
      <div ref={this.ref} className={className}>
        {children}
      </div>
    )
  }
}

Detector.propTypes = {
  children: any,
  className: string,
  eventName: string,
  onEvent: func
}

Detector.defaultProps = {
  children: null,
  onEvent: () => null,
  eventName: 'mousedown',
  className: ''
}
