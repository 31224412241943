import React from 'react'
import { shape, string, bool } from 'prop-types'
import cx from 'classnames'

// components
import Dropdown from '@components/Dropdown'
import Icon from '@components/Icon'
import List from '../List'

import * as models from '@modules/models'

// styles
import styles from './_.module.scss'

// hoc
import asTogglesConsumer from '@hoc/asTogglesConsumer'

const Subnav = ({ flag, item, isSubnav, current }) => {
  const isActive = current === item.url
  const DecoratedDropDown = () => (
    <Dropdown
      isOpen={false}
      handler={isOpen => (
        <div
          className={cx(
            'di-f',
            'alit-c',
            'fosi-2',
            'pa-2',
            'bora',
            'cu-p',
            styles.text,
            isActive ? 'co-white' : isSubnav ? 'co-gray-4' : 'co-primary-1',
            {
              'baco-primary-4': isOpen
            }
          )}
        >
          {item.text} <Icon name={item.icon} size={7} />
        </div>
      )}
      contentWrapperClassName={styles.avatarSubmenu}
    >
      <ul
        className={cx('di-f', 'fldi-c', 'baco-white', 'bora', styles.submenu)}
      >
        <List list={item.submenu} className="di-f" isSubnav />
      </ul>
    </Dropdown>
  )

  return item.isStrictFlag ? (flag ? <DecoratedDropDown/> : null) : <DecoratedDropDown/>
}

Subnav.propTypes = {
  item: shape(models.NavItem).isRequired,
  current: string,
  flag: bool,
  isSubnav: bool
}

Subnav.defaultProps = {
  current: '',
  flag: false,
  isSubnav: false
}

export default asTogglesConsumer(Subnav)
