import React from 'react'
import cx from 'classnames'
import { string } from 'prop-types'

// styles
import styles from './_.module.scss'

const date = new Date()

const Footer = ({ className }) => (
  <footer
    className={cx(
      'pay-2',
      'baco-gray-2',
      'co-gray-4',
      'di-f',
      'juco-c',
      'mat-4',
      styles.footer,
      className
    )}
    aria-label="Footer"
  >
    <div className={cx('di-f', 'fosi-1', 'teal-l', styles.inner)}>
      <span>
        Copyright &copy; {date.getFullYear()} A Place For Mom, Inc. &nbsp;
      </span>

      <span className="di-n-po">|&nbsp;</span>

      <span>
        For Customer Support, email <a href="mailto:solutions@aplaceformom.com" aria-label="For Customer Support, email solutions@aplaceformom.com">solutions@aplaceformom.com</a>
      </span>
    </div>
  </footer>
)

Footer.propTypes = {
  className: string
}

Footer.defaultProps = {
  className: ''
}

export default Footer
