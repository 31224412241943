import React from 'react'
import cx from 'classnames'
import { string, shape, bool, node, func, number } from 'prop-types'

// module
import withNotificationsTheme from '@hoc/withNotificationsTheme'

import Icon from '@components/Icon'
import { NOTIFICATIONS_THEMES } from '@components/constants'
import {
  NOTIFICATION_SUCCESS,
  NOTIFICATION_ERROR
} from '../../constants'

import styles from './_.module.scss'

const IconsMap = {
  [NOTIFICATION_ERROR]: {
    className: 'co-orange-3',
    name: 'close'
  },
  [NOTIFICATION_SUCCESS]: {
    className: 'co-primary-2',
    name: 'check'
  }
}

const IconType = ({ type }) => {
  const icon = IconsMap[type]
  if (icon)
    return <Icon className={cx("mar-2", icon.className)} name={icon.name} size={12} />
  return null
}

IconType.propTypes = {
  type: string.isRequired
}

export const Notification = ({
  type,
  message,
  theme,
  isClosing,
  animationMiliseconds,
  onMouseLeave,
  onMouseHover
}) => {
  const { color } = theme
  return (
    <div
      className={cx(
        styles.notification,
        'alit-c',
        'co-white',
        'di-f',
        'may-1',
        'pa-2',
        `baco-${color}`,
        {
          [styles.notificationClosed]: isClosing
        }
      )}
      onMouseEnter={() => onMouseHover()}
      onMouseLeave={() => onMouseLeave()}
      style={
        (animationMiliseconds && {
          transitionDuration: `${animationMiliseconds}ms`
        }) ||
        {}
      }
    >
      <IconType type={type} />
      <div className="fosi-2">{message}</div>
    </div>
  )
}

Notification.propTypes = {
  type: string.isRequired,
  message: node.isRequired,
  theme: shape({
    color: string,
    palette: string
  }),
  isClosing: bool,
  animationMiliseconds: number,
  onMouseHover: func,
  onMouseLeave: func
}

Notification.defaultProps = {
  theme: NOTIFICATIONS_THEMES.default,
  isClosing: false,
  animationMiliseconds: 400,
  onMouseHover: () => null,
  onMouseLeave: () => null
}

export default withNotificationsTheme(Notification)
