import React, { Fragment } from 'react'
import cx from 'classnames'
import { array, bool, string, func, node } from 'prop-types'

// components
import { Tabs } from '@components/Tabs'
import MessageResult from '../MessageResult'

const Presenter = ({
  id,
  data,
  isLoad,
  loadingComponent,
  noResultsComponent,
  itemFactory,
  errorMessage
}) => {
  return (
    <div
      id={id}
      className={cx(Tabs.tabContentClass, Tabs.desktopOnly)}
    >
      {isLoad ? (
        loadingComponent
      ) : errorMessage ? (
        <MessageResult>
          {errorMessage}
        </MessageResult>
      ) : data.length ? (
        <div className="di-f fldi-c">
          {data.map((item, index) => (
            <Fragment key={index}>{itemFactory(item, index)}</Fragment>
          ))}
        </div>
      ) : (
        noResultsComponent
      )}
    </div>
  )
}

Presenter.propTypes = {
  id: string.isRequired,
  itemFactory: func.isRequired,
  data: array,
  isLoad: bool,
  loadingComponent: node,
  noResultsComponent: node,
  errorMessage: string
}

Presenter.defaultProps = {
  data: [],
  isLoad: false,
  loadingComponent: <small>Loading...</small>,
  noResultsComponent: <small>No results found</small>,
  errorMessage: null
}

export default Presenter
