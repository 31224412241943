import React, { Component } from 'react'
import { any } from 'prop-types'

export default function(WrappedComponent) {
  class FordwardComponent extends Component {
    static propTypes = {
      forwardedRef: any
    }
    static defaultProps = {
      forwardedRef: null
    }

    render() {
      const { forwardedRef, ...rest } = this.props
      return <WrappedComponent ref={forwardedRef} {...rest} />
    }
  }
  return React.forwardRef((props, ref) => {
    return <FordwardComponent {...props} forwardedRef={ref} />
  })
}
