import React from 'react'
import {
  arrayOf,
  shape,
  string,
  number,
  oneOfType,
  object,
  bool,
  node
} from 'prop-types'
import cx from 'classnames'

import styles from './_.module.scss'

const LinkShape = shape({
  key: oneOfType([string, number]),
  text: node,
  url: string
})

const Breadcrumbs = ({ links, className, style, shouldWrap }) => {
  return (
    <ul
      className={cx(
        'di-f',
        'alit-c',
        'juco-s',
        'bora',
        'h1',
        styles.breadcrumbs,
        className,
        {
          'flwr-w': shouldWrap
        }
      )}
      style={style}
    >
      {links.map((item, index) => {
        if (item.text) {
          return (
            <li key={index} className={cx(styles.breadcrumb, 'di-f', 'alit-c')}>
              {index < links.length - 1 ? (
                <a className={cx('di-b')} href={item.url}>
                  {item.text}
                </a>
              ) : (
                <div className={cx(styles.breadcrumb, 'di-b')}>
                  <div role="heading" aria-level="1" className="co-black">
                    {item.text}
                  </div>
                </div>
              )}
            </li>
          )
        }

        return null
      })}
    </ul>
  )
}

Breadcrumbs.propTypes = {
  links: oneOfType([arrayOf(LinkShape), shape({})]),
  className: string,
  shouldWrap: bool,
  style: object
}

Breadcrumbs.defaultProps = {
  links: [],
  className: '',
  shouldWrap: false,
  style: {}
}

export default Breadcrumbs
