import {
  ADMIN_ROLE,
  MODERATOR_ROLE,
  IMPERSONATOR_ROLE
} from '@modules/constants'

export const menuLinks = [
  {
    url: '/dashboard',
    legacyUrl: 'http://partnercentral.aplaceformom.com/home',
    text: 'Dashboard',
    mobileIcon: 'trend-arrow',
    flag: 'dashboard'
  },
  {
    url: '/leads',
    legacyUrl: 'http://partnercentral.aplaceformom.com/leads',
    text: 'Leads',
    mobileIcon: 'checklist',
    flag: 'leads'
  },
  {
    url: '/communities',
    legacyUrl: 'http://partnercentral.aplaceformom.com/community',
    text: 'Communities',
    mobileIcon: 'house-home',
    flag: 'communities'
  },
  {
    url: '/reports',
    legacyUrl: 'http://partnercentral.aplaceformom.com/report',
    text: 'Reports',
    mobileIcon: 'folder',
    flag: 'reports'
  },
  {
    url: '/resources',
    text: 'Resources',
    icon: 'chevron-down',
    mobileIcon: 'books',
    flag: 'resources',
    isStrictFlag: true,
    submenu: [
      {
        url: '/resources/example-1',
        text: 'Example1'
      },
      {
        url: '/resources/example-2',
        text: 'Example2'
      },
      {
        url: '/resources/example-3',
        text: 'Example3'
      }
    ]
  },
  {
    url: '/moderator',
    text: 'Moderator',
    flag: 'moderator',
    isStrictFlag: true,
    roles: [MODERATOR_ROLE]
  }
]

export const avatarMenuLinks = {
  auth: [
    {
      url: '/admin-settings',
      text: 'Admin Settings',
      icon: '',
      roles: [ADMIN_ROLE]
    },
    {
      url: '/impersonation',
      text: 'Impersonate User',
      icon: '',
      roles: [IMPERSONATOR_ROLE]
    },
    {
      url: '/logout',
      text: 'Log Out',
      icon: ''
    }
  ],
  nonAuth: [
    {
      url: '/login',
      text: 'Login',
      icon: ''
    }
  ]
}

export const myprofileUrl = '/my-account'
export const searchUrl = '/search'
export const notificationsUrl = '/notifications'
