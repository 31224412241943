import React from 'react'
import cx from 'classnames'
import { array, string, shape, node, any, number } from 'prop-types'

// components
import Chart from '@components/Chart'
import Avatar from '@components/Avatar'

// modules
import datejs from '@modules/datejs'
import Cloudinary from '@modules/cloudinary'

// styles
import styles from './_.module.scss'

const Donut = ({ progress }) => (
  <Chart.Donut size={50} progress={progress} thickness={4} />
)

const MediaObject = props => (
  <>
    <div className={`di-f alit-c`}>{props.icon}</div>
  </>
)
const Score = scores => {
  const scoreSummary = getScoreTotal(scores)
  return <MediaObject icon={<Donut progress={scoreSummary} />} />
}

const getScoreTotal = scoresObj => {
  const completedScore = 100
  const scoreTotal = Object.values(scoresObj.scores.values).reduce(
    (actual, { value }) => actual + value,
    0
  )

  if (scoreTotal >= 99) {
    return completedScore
  } else {
    return Math.floor(scoreTotal)
  }
}

const Community = ({
  picture,
  community,
  contact,
  date,
  division,
  url,
  address,
  score
}) => {
  const communityName = community.map((item, index) => {
    return <span key={index}> {item} </span>
  })

  return (
    <a
      className={cx(
        'di-f',
        'fldi-c',
        'pay-2',
        'pax-4',
        'fosi-2',
        'cu-p',
        styles.leadBox
      )}
      href={url}
    >
      <div className="di-f fldi-r pab-2">
        <div className={cx('di-f', 'fldi-c', 'juco-c', 'mar-2')}>
          {picture ? (
            <div
              className={styles.pictureImage}
              style={{
                backgroundImage: `url(${Cloudinary.tranformImage(
                  picture,
                  'w_360'
                )})`
              }}
              title={`Thumbnail photo of ${communityName} property`}
            />
          ) : (
            <Avatar.Contained iconName="gallery" size="sm" />
          )}
        </div>
        <div className={cx('di-f', 'fldi-c', styles.communityName)}>
          <span className="co-black fowe-b">{communityName}</span>
          <div className={cx(styles.communityAddress)}>{address}</div>
        </div>
        <div className={cx('di-f', 'juco-fe', styles.score)}>
          <span className={cx('pax-2', 'co-gray-4')}>
            <Score scores={score} />
          </span>
        </div>
      </div>
      <div className="di-f fldi-r pab-2 pal-5">
        <div
          className={cx(
            'pal-1',
            'mal-4',
            'di-f',
            'fldi-c',
            styles.primaryContact
          )}
        >
          <span className="fosi-1 co-gray-3">Primary Contact</span>
          <span>
            {contact.firstName} {contact.lastName}
          </span>
        </div>
        <div className={cx('di-f', 'fldi-c', styles.communityDivision)}>
          <span className="fosi-1 co-gray-3">Division</span>
          <span>{division}</span>
        </div>
      </div>
      <div className="di-f juco-sb pal-5">
        <div
          className={cx('pal-1', 'mal-4', 'di-f', 'fldi-c', styles.lastUpdate)}
        >
          <span className="fosi-1 co-gray-3">
            Last Updated on {datejs(date).format('MM/DD/YYYY')}
          </span>
        </div>
      </div>
    </a>
  )
}

Donut.propTypes = {
  progress: number.isRequired
}

MediaObject.propTypes = {
  icon: any
}

MediaObject.defaultProps = {
  icon: null
}

Community.propTypes = {
  date: string,
  contact: shape({ firstName: node, lastName: node }),
  community: array,
  url: string,
  picture: string,
  address: string,
  division: string,
  score: any
}

Community.defaultProps = {
  community: [],
  picture: '',
  division: '',
  address: '',
  date: '',
  contact: {},
  url: '',
  score: []
}

export default Community
