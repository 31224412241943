import React, { useState } from 'react'
import { func, string } from 'prop-types'

// components
import MaskedTextField from '../MaskedTextField'

const CountryPhone = ({onBlur, value, ...props}) => {
  const extMask = '(999) 999-9999 x9999'
  const noExtMask = '(999) 999-9999'
  const [mask, setMask] = useState(value.length > 10 ? extMask : noExtMask)
  const [error, setError] = useState(null)

  return (
    <MaskedTextField
      mask={mask}
      value={value}
      onBlur={(event, setValue) => {
        const currentTarget = (event && event.currentTarget) || {}
        if(currentTarget.value.length <= 16) setMask(noExtMask)
        if(currentTarget.value.length < 14) setError('Phone numbers must be at least 10 digits long.')
        if(currentTarget.value.length === 0 || currentTarget.value.length >= 14) setError(null)
        onBlur(event, setValue)
      }}
      onFocus={() => {
        if(extMask !== mask) setMask(extMask)
      }}
      hasErrorMessage={error}
      {...props}
    />
  )
}

CountryPhone.propTypes = {
  value: string,
  onBlur: func
}

CountryPhone.defaultProps = {
  value: '',
  onBlur: () => null
}


export default CountryPhone
