import { useState, useEffect } from 'react'

// modules
import { ADMIN_ROLE, USER_ROLES } from '@modules/constants'
import UserRoles from '@modules/userRoles'
import Auth from '@modules/auth'

const useIsAdmin = (userType = USER_ROLES, refetch = false) => {
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    Auth.clientIsAuth((_, isAuth) => {
      if (isAuth) {
        UserRoles.hasNeededRoles(userType, [ADMIN_ROLE], (err, userHasAccess) => {
          if (err) {
            setIsAdmin(false)
          } else {
            setIsAdmin(userHasAccess)
          }
        }, refetch)
      }
    })
  }, [userType, refetch])

  return isAdmin
}

export default useIsAdmin
