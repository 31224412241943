import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { string, arrayOf, shape, func } from 'prop-types'

// hooks
import useIsAdmin from '@hooks/useIsAdmin'
import useIsModerator from '@hooks/useIsModerator'
import useIsImpersonator from '@hooks/useIsImpersonator'

// Modules
import Auth from '@modules/auth'
import UserRoles from '@modules/userRoles'
import * as models from '@modules/models'
import {
  REST_API_ENDPOINTS,
  IMPERSONATED_USER_ROLES,
  ADMIN_ROLE,
  MODERATOR_ROLE
} from '@modules/constants'
import {
  noAuthLinks,
  adminLinks,
  moderatorLinks,
  impersonatorLinks
} from '../../modules/utils'

// Components
import Dropdown from '@components/Dropdown'
import Notifications from '@components/Notifications'
import Avatar from '@components/Avatar'
import XHRTextField from '@components/XHRTextField'
import UniversalSearchPanel from '@components/UniversalSearchPanel'
import FeatureToggle from '@components/FeatureToggle'

// Styles
import styles from './_.module.scss'

const API_URL = process.env.GATSBY_PC_API_URL

const Desktop = props => {
  const isAdminHook = useIsAdmin()
  const [isAdmin, setIsAdmin] = useState(isAdminHook)
  const isModeratorHook = useIsModerator()
  const [isModerator, setIsModerator] = useState(isModeratorHook)
  const isImpersonator = useIsImpersonator()

  useEffect(() => {
    const setCurrentUserAccess = () => {
      if (isAdminHook) {
        setIsAdmin(isAdminHook)
      }
      if (isModeratorHook) {
        setIsModerator(isModeratorHook)
      }
    }
    if (isImpersonator) {
      Auth.isoIsImpersonating((_, isImpersonating) => {
        if (isImpersonating) {
          UserRoles.getUserRoles((err, roles) => {
            if (err) {
              setIsAdmin(false)
              return setIsModerator(false)
            }
            const hasAdminAccess = UserRoles.checkRolesAccess(
              roles,
              [ADMIN_ROLE],
              IMPERSONATED_USER_ROLES
            )
            const hasModeratorAccess = UserRoles.checkRolesAccess(
              roles,
              [MODERATOR_ROLE],
              IMPERSONATED_USER_ROLES
            )
            setIsAdmin(hasAdminAccess)
            setIsModerator(hasModeratorAccess)
          })
        } else {
          setCurrentUserAccess()
        }
      })
    } else {
      setCurrentUserAccess()
    }
  }, [isAdminHook, isImpersonator, isModeratorHook])

  const {
    avatarMenuLinks,
    avatarMenu,
    menuLinks,
    current,
    className,
    profile,
    children
  } = props

  const firstName = profile.given_name
  const lastName = profile.family_name
  const email = profile.nickname
  const navBarLinks = [
    ...noAuthLinks(menuLinks),
    ...(isModerator ? moderatorLinks(menuLinks) : [])
  ]

  return (
    <div className="di-n-tpo di-f-tp fldi-c pay-2 baco-primary-3">
      <div
        className={cx(
          'di-f',
          'alit-c',
          'juco-sb',
          styles.navDesktop,
          className
        )}
      >
        <div className={styles.logoSection}>
          <img
            src="/logo-cc.svg"
            alt="A Place For Mom, Community Central Logo"
            width={197}
            className={styles.logo}
          />
        </div>
        <div className={cx(styles.menuSection)}>
          {children(navBarLinks, current)}
        </div>

        <FeatureToggle name="universalSearch">
          <div className={styles.searchSection}>
            {/* @TODO: Change URLs and Adapt Presenter UI */}
            <XHRTextField
              urlsFactory={search => ({
                leads: `${API_URL}/${REST_API_ENDPOINTS.referralsSearch}?search=${search}`,
                communities: `${API_URL}/${REST_API_ENDPOINTS.communitySearch}?search=${search}`
              })}
              presenterFactory={UniversalSearchPanel}
              presenterStyles={{ right: 0 }}
              errorMessage="An error was encountered while searching. Please try again later"
              minimumCharactersLength={3}
            />
          </div>
        </FeatureToggle>

        <FeatureToggle name="globalNotifications">
          <div className={styles.notificationsSection}>
            <Notifications count={2} />
          </div>
        </FeatureToggle>

        <div className={styles.avatarSection}>
          <Dropdown
            isOpen={false}
            handler={isOpen => (
              <Avatar
                size={40}
                picture={(profile && profile.picture) || ''}
                firstName={firstName || ''}
                lastName={lastName || ''}
                email={email || ''}
                className={cx('cu-p', 'bora-round', 'baco-primary-1', {
                  'boco-white bowi-2 bost-s': isOpen
                })}
                fallbackIconConfig={{
                  className: 'co-white'
                }}
              />
            )}
            contentWrapperClassName={styles.avatarSubmenu}
          >
            <ul>
              {avatarMenu(
                profile && profile.name
                  ? [
                      ...(isAdmin ? adminLinks(avatarMenuLinks.auth) : []),
                      ...(isImpersonator
                        ? impersonatorLinks(avatarMenuLinks.auth)
                        : []),
                      ...noAuthLinks(avatarMenuLinks.auth)
                    ]
                  : avatarMenuLinks.nonAuth
              )}
            </ul>
          </Dropdown>
        </div>
      </div>
    </div>
  )
}

Desktop.propTypes = {
  className: string,
  menuLinks: arrayOf(shape(models.NavItem)),
  avatarMenuLinks: shape({
    auth: arrayOf(shape(models.NavItem)),
    nonAuth: arrayOf(shape(models.NavItem))
  }),
  avatarMenu: func,
  current: string,
  profile: shape(models.Profile),
  children: func
}

Desktop.defaultProps = {
  className: null,
  menuLinks: [],
  avatarMenuLinks: {
    auth: [],
    nonAuth: []
  },
  avatarMenu: () => null,
  current: '',
  profile: {},
  children: null
}

export default Desktop
