// Components
import MediaObject from './components/MediaObject'
import Sidebar from './components/Sidebar'
import Content from './components/Content'

import { FLOAT_LEFT, FLOAT_RIGHT } from './constants'

MediaObject.Sidebar = Sidebar
MediaObject.Content = Content

// Export variables to be used in JSX
MediaObject.FLOAT_LEFT = FLOAT_LEFT
MediaObject.FLOAT_RIGHT = FLOAT_RIGHT

export { Sidebar, Content }
export default MediaObject
