import { useState, useEffect } from 'react'

// modules
import {
  MODERATOR_ROLE,
  USER_ROLES,
  IMPERSONATED_USER_ROLES
} from '@modules/constants'
import UserRoles from '@modules/userRoles'
import Auth from '@modules/auth'

const useIsModerator = () => {
  const [isModerator, setIsModerator] = useState(false)

  useEffect(() => {
    Auth.clientIsAuth((err, isAuth) => {
      if (isAuth) {
        Auth.isoIsImpersonating((_, isImpersonating) => {
          const userType = isImpersonating
            ? IMPERSONATED_USER_ROLES
            : USER_ROLES
          UserRoles.hasNeededRoles(userType, [MODERATOR_ROLE], (err, userHasAccess) => {
            if (err) {
              setIsModerator(false)
            } else {
              setIsModerator(userHasAccess)
            }
          })
        })
      }
    })
  }, [])

  return isModerator
}

export default useIsModerator
