export const DEFAULT_AVATAR_SIZE = 40
export const COLORS = [
  '#EE5A24',
  '#009432',
  '#0652DD',
  '#1289A7',
  '#ED4C67',
  '#9980FA',
  '#1B1464'
]
