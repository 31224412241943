import React, { Component } from 'react'
import cx from 'classnames'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import {
  shape,
  array,
  arrayOf,
  oneOfType,
  object,
  any,
  bool,
  func,
  string,
  element,
  node
} from 'prop-types'

// modules
import * as selectors from '@modules/redux/selectors'
import * as actions from '@modules/redux/actions'
import * as models from '@modules/models'
import Router from '@modules/router'
import { List } from '@modules/inmutable'

// components
import Nav from '@components/Nav'
import Menu from '@components/Menu'
import Breadcrumbs from '@components/Breadcrumbs'

import ContentWrapper from '../ContentWrapper'

// styles
import styles from './_.module.scss'

const CustomBreadcrumbs = props => (
  <Breadcrumbs className={styles.breadcrumbs} {...props} />
)

const avatarMenu = avatarLinks => (
  <Menu.List list={avatarLinks} direction={Menu.DIRECTION_COLUMN} isSubnav />
)
class Header extends Component {
  componentDidMount() {
    const { fetchProfile } = this.props
    fetchProfile()
  }

  render() {
    const {
      breadcrumbs,
      syntheticBreadcrumbItems,
      profile,
      profileRequestStatus,
      profileRequestError,
      internalHeader,
      children,
      className
    } = this.props
    const combinedBreadcrumbsLinks = List.concat(
      syntheticBreadcrumbItems,
      breadcrumbs
    )
    const isInternalPage = List.getSize(combinedBreadcrumbsLinks) > 1
    const pathname = List.getFirst(combinedBreadcrumbsLinks)
    const location = pathname.url || ''
    const last = List.getLast(combinedBreadcrumbsLinks)
    const title = (last && last.text) || ''
    return (
      <div>
        <div className={cx('baco-primary-3', className)}>
          <div className={cx('ml-a', 'mr-a', styles.wrapper)}>
            <Nav
              current={location}
              profile={
                (!profileRequestStatus && !profileRequestError && profile) || {}
              }
              avatarMenu={avatarMenu}
              onMobileClickGoto={url => Router.go(url)}
              onBackClick={() => Router.go(location || '/')}
              internalHeader={internalHeader}
              isInternalPage={isInternalPage}
              title={title}
            >
              {(menuLinks, current, isMobileOnly) => (
                <Menu
                  list={menuLinks}
                  current={current}
                  isMobileOnly={isMobileOnly}
                />
              )}
            </Nav>
          </div>
        </div>
        <div
          className={cx(
            'ml-a',
            'mr-a',
            'baco-white',
            styles.wrapper,
            className,
            'juco-sb',
            'alit-c',
            isInternalPage ? 'di-n-po di-f-p' : 'di-f'
          )}
        >
          <ContentWrapper>
            <CustomBreadcrumbs links={combinedBreadcrumbsLinks} />
          </ContentWrapper>
          {children}
        </div>
      </div>
    )
  }
}

Header.propTypes = {
  breadcrumbs: arrayOf(shape({})),
  className: string,
  fetchProfile: func,
  internalHeader: element,
  profile: shape(models.Profile),
  profileRequestError: any,
  profileRequestStatus: bool,
  syntheticBreadcrumbItems: oneOfType([array, object]),
  children: node
}

Header.defaultProps = {
  breadcrumbs: [],
  className: '',
  fetchProfile: () => null,
  internalHeader: null,
  profile: {},
  profileRequestError: null,
  profileRequestStatus: false,
  syntheticBreadcrumbItems: [],
  children: null
}

const mapStateToProps = createStructuredSelector({
  syntheticBreadcrumbItems: selectors.getSyntheticBreadcrumbItems(),
  profile: selectors.getProfile(),
  profileRequestStatus: selectors.getProfileRequestStatus(),
  profileRequestError: selectors.getProfileRequestError()
})

export default connect(
  mapStateToProps,
  actions
)(Header)
