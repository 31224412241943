import React from 'react'
import { string, any } from 'prop-types'

const Labeled = ({ label, Constructor, ...rest }) => (
  <label>
    <div className="mat-2 fosi-1 co-gray-3">{label}</div>
    <Constructor {...rest} className="mat-1" palette="white" />
  </label>
)

Labeled.propTypes = {
  label: string.isRequired,
  Constructor: any.isRequired
}

export default Labeled
