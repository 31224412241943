import React from 'react'
import cx from 'classnames'
import { func } from 'prop-types'

// components
import { Tabs, Tab } from '@components/Tabs'

// styles
import styles from './_.module.scss'

const Header = ({ setTabsRef }) => {
  return (
    <Tabs
      key="search-tabs"
      ref={tabsComponent => {
        setTabsRef(tabsComponent)
      }}
      className="ovx-a pal-2 pat-2"
      isDesktopOnly={true}
      initialTabActive="#top"
      id="universal-search-tabs"
    >
      <Tab link="#top" className={cx('whsp-nw', styles.tab)}>
        Top Results
      </Tab>
      <Tab link="#leads" className={cx('whsp-nw', styles.tab)}>
        Leads
      </Tab>
      <Tab link="#communities" className={cx('whsp-nw', styles.tab)}>
        Communities
      </Tab>
    </Tabs>
  )
}

Header.propTypes = {
  setTabsRef: func
}
Header.defaultProps = {
  setTabsRef: () => null
}

export default Header
