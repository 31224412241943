import React from 'react'
import { node, string, oneOfType, number, oneOf } from 'prop-types'
import cx from 'classnames'

import { FLOAT_LEFT, FLOAT_RIGHT } from '../../constants'

// Styles
import styles from './_.module.scss'

const Content = ({ children, className, width, float, ...rest }) => (
  <div
    className={cx(
      styles.body,
      float === FLOAT_LEFT ? 'or-2' : 'or-1',
      className
    )}
    style={{ width }}
    {...rest}
  >
    {children}
  </div>
)

Content.propTypes = {
  children: node,
  className: string,
  float: oneOf([FLOAT_LEFT, FLOAT_RIGHT]),
  width: oneOfType([number, string])
}

Content.defaultProps = {
  className: '',
  width: '',
  float: FLOAT_LEFT,
  children: null
}

export default Content
