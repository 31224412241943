import React from 'react'
import { element, string } from 'prop-types'
import cx from 'classnames'

// components
import TextField from '../TextField'

// styles
import styles from './_.module.scss'

const WithIcon = ({ icon, className, inputClassName, ...rest }) => (
  <TextField
    className={cx('po-r', styles.wrapper, inputClassName)}
    textFieldClassName={styles.textField}
    {...rest}
    withIcon
    icon={
      <span
        className={cx('po-a', 'di-f', 'alit-c', 'pat-2', styles.iconWrapper)}
      >
        {icon}
      </span>
    }
  />
)

WithIcon.propTypes = {
  icon: element.isRequired,
  className: string,
  inputClassName: string
}

WithIcon.defaultProps = {
  className: null,
  inputClassName: null
}

export default WithIcon
