import React, { Component } from 'react'

// modules
import { NOTIFICATIONS_THEMES } from '@components/constants'

const DEFAULT_NOTIFICATIONS_THEME = NOTIFICATIONS_THEMES.notification_default

export default function(WrappedComponent) {
  return class extends Component {
    render() {
      const { props } = this
      const theme =
        NOTIFICATIONS_THEMES[props.type] || DEFAULT_NOTIFICATIONS_THEME // eslint-disable-line react/prop-types
      return <WrappedComponent {...props} theme={theme} />
    }
  }
}
