import React from 'react'
import cx from 'classnames'
import { func, bool, node, string, array } from 'prop-types'

// components
import { Tabs } from '@components/Tabs'
import Icon from '@components/Icon'

// components
import MessageResult from '../MessageResult'
import Referral from '../Referral'
import Community from '../Community'

// styles
import styles from './_.module.scss'

const NoResultsComponent = () => (
  <MessageResult>No results found.</MessageResult>
)

const TopResults = ({
  loadingComponent,
  isLoad,
  errorMessage,
  onGoToTab,
  leads,
  communities
}) => {
  const communitiesCount = communities.length
  const leadsCount = leads.length
  const totalResults = leadsCount + communitiesCount

  const totalLeads = leadsCount > 2 ? 2 : leadsCount <= 2 ? leadsCount : 0
  const totalCommunities = communitiesCount > 2 ? 2 : communitiesCount <= 2 ? communitiesCount : 0

  const topCommunities = communities.slice(0,2)
  const topLeads = leads.slice(0,2)

  return (
    <div id="top" className={cx(Tabs.tabContentClass, Tabs.desktopOnly, Tabs.activeTabContent)}>
      {isLoad ? (
        loadingComponent
      ) : errorMessage ? (
        <MessageResult>{errorMessage}</MessageResult>
      ) : (
        <>
          {totalResults > 0 ? (
            <>
              <div className={cx('', styles.divider)}>
                <>
                  <a
                    href="#/"
                    onClick={onGoToTab('leads')}
                    className="di-f juco-sb baco-gray-1"
                  >
                    <div className="pax-4 pay-2 co-gray-3">
                      {totalLeads} of {leadsCount} Leads
                    </div>
                    <Icon name="chevron-right co-gray-3" size={20} />
                  </a>
                  {!leadsCount ? (
                    <NoResultsComponent />
                  ) : (
                   topLeads.map((item, index) => {
                      const { referredDate, lead, referralStatus, community } = item || {}
                      const { id: communityId } = community || {}
                      return (
                        <Referral
                          key={index}
                          url={`/leads/${lead.leadId}?communityId=${communityId}`}
                          date={referredDate}
                          status={referralStatus}
                          contact={lead.contact || ''}
                          resident={lead.resident || ''}
                          advisor={lead.advisor || ''}
                          community={community}
                        />
                      )
                    })
                  )}
                </>
              </div>              
                <div className={cx('', styles.divider)}>
                <>
                  <a
                    href="#/"
                    onClick={onGoToTab('communities')}
                    className="di-f juco-sb baco-gray-1"
                  >
                    <div className="pax-4 pay-2 co-gray-3">
                      {totalCommunities} of {communitiesCount} Communities
                    </div>
                    <Icon name="chevron-right co-gray-3" size={20} />
                  </a>
                  {!communitiesCount ? (
                    <NoResultsComponent />
                  ) : (
                    topCommunities.map((item, index) => {
                      const {
                        id,
                        picture,
                        dateUpdated,
                        name,
                        division,
                        contact,
                        address,
                        score
                      } = item || {}

                      return (
                        <Community
                          key={index}
                          url={`/communities/${id}`}
                          picture={picture}
                          community={name}
                          contact={contact || ''}
                          date={dateUpdated}
                          division={division}
                          address={address}
                          score={score}
                        />
                      )
                    })
                  )}
                </>
              </div>
            </>
          ) : (
            <NoResultsComponent />
          )}
        </>
      )}
    </div>
  )
}

TopResults.propTypes = {
  isLoad: bool,
  errorMessage: string,
  loadingComponent: node,
  leads: array,
  communities: array,
  onGoToTab: func
}

TopResults.defaultProps = {
  isLoad: false,
  errorMessage: null,
  loadingComponent: null,
  leads: {},
  communities: {},
  onGoToTab: () => null
}

export default TopResults
