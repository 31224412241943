import React, { Component } from 'react'
import { Link } from '@reach/router'
import { any, string, func } from 'prop-types'

export class TextLink extends Component {
  render() {
    const { children, to, href, onClick, ...rest } = this.props
    const destination = to || href
    const internal = /^\/(?!\/)/.test(destination)

    if (internal) {
      return (
        <Link to={destination} {...rest} onClick={onClick}>
          {children}
        </Link>
      )
    }
    return (
      <a href={destination} {...rest}>
        {children}
      </a>
    )
  }
}

TextLink.propTypes = {
  children: any,
  to: string,
  href: string,
  onClick: func
}

TextLink.defaultProps = {
  children: null,
  to: '',
  href: '',
  onClick: () => null
}

export default TextLink
