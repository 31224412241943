import React from 'react'
import cx from 'classnames'
import { bool, array, shape, string, any } from 'prop-types'

// components
import Header from './components/Header'
import TopResults from './components/TopResults'
import Presenter from './components/Presenter'
import Referral from './components/Referral'
import Community from './components/Community'

// components
import MessageResult from './components/MessageResult'

// modules
import Highlight from '@modules/highlight'

// styles
import styles from './_.module.scss'

let tabsRef = null
const goToTab = name => event => {
  event.preventDefault()
  tabsRef && tabsRef.goToTab(`#${name}`)
}

const NoResultsComponent = () => <MessageResult>No results found.</MessageResult>
const LoadingComponent = <MessageResult>Loading...</MessageResult>

const UniversalSearchPanel = ({ query, isLoad, datasets, error }) => {
  
  const { leads: leadsDataset, communities: communitiesDataset } =
    datasets || {}

  const { results: leadsResults } = leadsDataset || { results: [] }
  const { results: communitiesResults } = communitiesDataset || { results: [] }

  const leads =
    leadsResults && leadsResults.length > 0
      ? Highlight.results(query, leadsResults)
      : []

  const communities =
  communitiesResults && communitiesResults.length > 0
      ? Highlight.results(query, communitiesResults)
      : []
      
  const errorMessage = error
    ? 'An error was encountered while searching. Please try again later.'
    : null

  return (
    <div className={cx('po-r', styles.universalSearchPanel)}>
      <Header
        setTabsRef={ref => (tabsRef = ref)}
      />
      <TopResults
        isLoad={isLoad}
        onGoToTab={goToTab}
        errorMessage={errorMessage}
        leads={leads}
        communities = {communities}
        isTabActive
        loadingComponent={LoadingComponent}
      />
      <Presenter
        id="leads"
        isLoad={isLoad}
        data={leads}
        errorMessage={errorMessage}
        itemFactory={(item, index) => {
          const { referredDate, lead, referralStatus, community } = item || {}
          const { id: communityId } = community  || {}
          return (
            <Referral
              key={index}
              url={`/leads/${lead.leadId}?communityId=${communityId}`}
              date={referredDate}
              status={referralStatus}
              contact={lead.contact || ''}
              resident={lead.resident || ''}
              advisor={lead.advisor || ''}
              community={community}
            />
          )
        }}
        noResultsComponent={NoResultsComponent('Leads')}
        loadingComponent={LoadingComponent}
      />
      <Presenter
        id="communities"
        isLoad={isLoad}
        data={communities}
        errorMessage={errorMessage}
        itemFactory={(item, index) => {
          const { id, picture, dateUpdated, name, division, contact, address, score } = item || {}
          return (
            <Community
              key={index}
              url={`/communities/${id}`}
              picture = {picture}
              community={name}
              contact={contact || ''}
              date={dateUpdated}
              division={division}
              address = {address}
              score = {score}
            />
          )
        }}
        noResultsComponent={NoResultsComponent('Communities')}
        loadingComponent={LoadingComponent}
      />
    </div>
  )
}

UniversalSearchPanel.propTypes = {
  query: string,
  isLoad: bool,
  datasets: shape({
    leads: array,
    communities: array,
    users: array
  }),
  error: any
}

UniversalSearchPanel.defaultProps = {
  query: '',
  isLoad: false,
  datasets: {
    leads: {},
    communities: {},
    users: {}
  },
  error: {}
}

export default UniversalSearchPanel
