/* eslint react/prop-types: 0 */
import React, { Component } from 'react'

// modules
import { SIZES } from '@modules/constants'

export default function(WrappedComponent) {
  return class extends Component {
    render() {
        const sizeLowercase = this.props.size.toLowerCase ? this.props.size.toLowerCase() : 'md'
        const size = SIZES.indexOf(sizeLowercase) > -1 ? sizeLowercase : 'md'
        
      return <WrappedComponent {...this.props} size={size} />
    }
  }
}
