import React from 'react'
import cx from 'classnames'
import { string, node, bool, func } from 'prop-types'

// HOC
import withPalette from '@hoc/withPalette'

// Styles
import styles from './_.module.scss'
import { THEMES } from '@components/constants'

export const Tab = ({
  id,
  link,
  isActive,
  children,
  onClick,
  isFill,
  palette,
  className
}) => {
  const activeClass = isFill ? 'co-white' : `co-${palette}-3`
  const inactiveClass = isFill ? `co-${palette}-1` : `co-gray-3`
  const activeThemeClass = styles[`tab${palette}`] || styles.tabprimary
  return (
    <li
      className={cx(
        'fosi-2',
        'alit-c',
        'teal-c',
        styles.tab,
        activeThemeClass,
        isActive ? styles.active : '',
        isFill ? styles.fill : '',
        className
      )}
    >
      <a
        id={id}
        href={link}
        onClick={onClick}
        className={cx('di-b', 'pay-2', 'pax-2', 'po-r')}
      >
        <span className={isActive ? activeClass : inactiveClass}>
          {children}
        </span>
        <span
          className={cx(
            styles.bottomBorder,
            isActive ? (isFill ? 'baco-white' : `baco-${palette}-3`) : ''
          )}
        />
      </a>
    </li>
  )
}

Tab.propTypes = {
  id: string,
  children: node,
  className: string,
  isActive: bool,
  isFill: bool,
  link: string,
  palette: string,
  onClick: func
}

Tab.defaultProps = {
  id: null,
  children: null,
  className: null,
  isActive: false,
  isFill: false,
  link: '/',
  onClick: () => null,
  palette: THEMES.primary
}

export default withPalette(Tab)
