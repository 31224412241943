import React from 'react'
import { any, object } from 'prop-types'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

// styles
import '../../styles/overrides.scss'

// components
import TextField from '../TextField'

const TextFieldTimePicker = ({ attrs, value, ...rest }) => (
  <TextField
    Constructor={DatePicker}
    {...rest}
    attrs={{
      showTimeSelect: true,
      showTimeSelectOnly: true,
      timeIntervals: 10,
      dateFormat: 'h:mm aa',
      timeCaption: 'Time',
      value,
      ...attrs
    }}
    isFormsy={false}
  />
)

TextFieldTimePicker.propTypes = {
  attrs: object,
  value: any
}

TextFieldTimePicker.defaultProps = {
  attrs: {},
  value: ''
}

export default TextFieldTimePicker
