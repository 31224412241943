import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import cx from 'classnames'
import { func, any } from 'prop-types'

// modules
import * as actions from '@modules/redux/actions'
import * as selectors from '@modules/redux/selectors'
import { List } from '@modules/inmutable'

import Notification from './components/Notification'
import * as Type from './constants'

// styles
import styles from './_.module.scss'

export const ToastNotify = {
  notify: () => null,
  success: () => null,
  info: () => null,
  error: () => null,
  warning: () => null,
  remove: () => null
}

const STATE_NOTIFICATIONS = 'toast_notifications'
const INTERVAL_MILISECONDS = 5000
const ANIMATION_MILISECONDS = 400
export class Toast extends Component {
  state = {
    [STATE_NOTIFICATIONS]: []
  }
  constructor(props) {
    super(props)
    this.timers = {}
    ToastNotify.notify = notification => this.notify(notification)
    ToastNotify.default = (message, opts) => this.notify({ ...opts, message })
    ToastNotify.success = (message, opts) =>
      this.getMethod(Type.NOTIFICATION_SUCCESS, message, opts)
    ToastNotify.info = (message, opts) =>
      this.getMethod(Type.NOTIFICATION_INFO, message, opts)
    ToastNotify.error = (message, opts) =>
      this.getMethod(Type.NOTIFICATION_ERROR, message, opts)
    ToastNotify.warning = (message, opts) =>
      this.getMethod(Type.NOTIFICATION_WARNING, message, opts)
    ToastNotify.remove = id => this.remove(id)
  }
  static getDerivedStateFromProps(props) {
    return {
      [STATE_NOTIFICATIONS]: props.notifications || []
    }
  }
  notify({ opts, ...notification }) {
    const { notifications, addNotification } = this.props
    const id = List.getSize(notifications) + 1
    addNotification({
      id,
      type: Type.NOTIFICATION_DEFAULT,
      ...notification
    })
    if (opts.autohide) {
      this.startTimer(id)
    }
    return id
  }
  getMethod(type, message, opts = {}) {
    return this.notify({
      type,
      message,
      opts: Object.assign({ autohide: true }, opts)
    })
  }
  startTimer(id) {
    this.timers[id] = setTimeout(() => {
      this.onClosedNotification(id)
    }, INTERVAL_MILISECONDS)
  }
  stopTimer(id) {
    clearTimeout(this.timers[id])
  }
  remove(id) {
    this.stopTimer(id)
    const { removeNotification } = this.props
    removeNotification(id)
  }
  onClosedNotification(id) {
    const { updateNotification } = this.props
    updateNotification(id, { isClosing: true })
    setTimeout(() => this.remove(id), ANIMATION_MILISECONDS)
  }

  render() {
    const notifications = this.state[STATE_NOTIFICATIONS]
    return (
      <div
        className={cx(
          'po-f',
          'di-f',
          'pa-1',
          'fldi-c',
          styles.notificationPool
        )}
      >
        {notifications.map((notification, key) => {
          if (notification && notification.type && notification.message) {
            return (
              <Notification
                key={key}
                id={notification.id}
                type={notification.type}
                isClosing={notification.isClosing || false}
                message={notification.message}
                animationMiliseconds={ANIMATION_MILISECONDS}
                onMouseHover={() => this.stopTimer(notification.id)}
                onMouseLeave={() => this.startTimer(notification.id)}
              />
            )
          }

          return null
        })}
      </div>
    )
  }
}

Toast.propTypes = {
  notifications: any,
  addNotification: func,
  removeNotification: func,
  updateNotification: func
}

Toast.defaultProps = {
  notifications: [],
  addNotification: () => null,
  removeNotification: () => null,
  updateNotification: () => null
}

export { Type }

const mapStateToProps = createStructuredSelector({
  notifications: selectors.getNotificationPool()
})

export default connect(mapStateToProps, actions)(Toast)
