import React from 'react'
import { string, any } from 'prop-types'

// components
import FeatureToggle from '../FeatureToggle'

// modules
import Router from '@modules/router'

const With404Fallback = props => {
  return (
    <FeatureToggle name={props.name} offAction={() => Router.go('/404')}>
      {props.children}
    </FeatureToggle>
  )
}

With404Fallback.propTypes = {
  name: string.isRequired,
  children: any
}

With404Fallback.defaultProps = {
  children: null
}

export default With404Fallback
