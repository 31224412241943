export class List {
  static isInmutable = collection => collection && collection.size !== undefined
  static getSize = collection =>
    List.isInmutable(collection) ? collection.size : collection.length
  static getFirst = (collection, defaultValue = {}) => {
    if (!List.getSize(collection)) return defaultValue
    return List.isInmutable(collection)
      ? collection.get(0, defaultValue)
      : collection[0]
  }
  static getLast = (collection, defaultValue = {}) => {
    if (!List.getSize(collection)) return defaultValue
    return List.isInmutable(collection)
      ? collection.last(defaultValue, defaultValue)
      : collection[collection.length - 1]
  }
  static concat = (list1, list2) => list1.concat(list2).reverse()
}
