import React from 'react'
import initials from 'initials'
import cx from 'classnames'
import contrast from 'contrast'
import { string, bool, number, object } from 'prop-types'

// modules
import Formatter from '@modules/formatter'
import { DEFAULT_AVATAR_SIZE, COLORS } from './modules/constants'

// styles
import styles from '../../_.module.scss'

// components
import Icon from '@components/Icon'

const Avatar = ({
  picture,
  firstName,
  lastName,
  email,
  borderLeftRadius,
  size,
  width,
  height,
  className,
  fallbackIconConfig,
  iconName
}) => {
  const {
    className: fallbackIconConfigClassName,
    ...restFromFallbackIconConfig
  } = fallbackIconConfig

  let name = firstName && Formatter.getFirstWord(firstName)
  name = name ? `${name} ${Formatter.getFirstWord(lastName)}` : lastName
  if (!name && email) name = email
  name = name ? name.toString().toUpperCase() : ''
  const abbr = initials(name)
  const isGravatar = picture
    ? picture.indexOf('s.gravatar.com/avatar') !== -1
    : false
  let randomColor = name ? Formatter.sumChars(name) % COLORS.length : 0;
  const bgColor = COLORS[randomColor]
  const textColor = contrast(bgColor)
  return picture && (!isGravatar || !name) ? (
    <div
      className={cx(
        'di-f',
        'alit-c',
        styles.avatar,
        borderLeftRadius ? styles.borderLeft : '',
        className
      )}
      style={{
        backgroundImage: `url('${picture}')`,
        width: width || size,
        height: height || size,
        borderRadius: '50%'
      }}
    />
  ) : abbr ? (
    <div
      className={cx(
        'di-f',
        'juco-c',
        'alit-c',
        'tetr-c',
        styles.avatar,
        borderLeftRadius ? styles.borderLeft : '',
        styles[textColor],
        className
      )}
      style={{ backgroundColor: bgColor }}
    >
      {abbr}
    </div>
  ) : (
    <Icon
      name={iconName}
      {...restFromFallbackIconConfig}
      className={cx(styles.avatarNoPicture, fallbackIconConfigClassName)}
    />
  )
}
Avatar.propTypes = {
  borderLeftRadius: bool,
  className: string,
  firstName: string,
  lastName: string,
  email: string,
  height: number,
  picture: string,
  size: number,
  width: number,
  fallbackIconConfig: object,
  iconName: string
}

Avatar.defaultProps = {
  borderLeftRadius: false,
  size: DEFAULT_AVATAR_SIZE,
  className: '',
  firstName: null,
  lastName: null,
  email: null,
  height: DEFAULT_AVATAR_SIZE,
  picture: '',
  width: DEFAULT_AVATAR_SIZE,
  fallbackIconConfig: {},
  iconName: 'account-round'
}

export default Avatar
