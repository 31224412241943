import {
  SET_COUNTER,
  SET_AUTH_STATE,
  SET_PROFILE,
  SET_IS_VIEW_AS_OPEN,
  SET_COLLECTION_REQUEST_STATUS,
  SET_COLLECTION_REQUEST_ERROR,
  SET_COLLECTION,
  SET_RESOURCE_REQUEST_STATUS,
  SET_RESOURCE_REQUEST_ERROR,
  SET_RESOURCE,
  SET_MOBILE_MENU,
  SET_IS_VIEW_AS_SELECTED,
  SET_VIEWING_AS,
  SET_LOCATION,
  SET_PROFILE_REQUEST_STATUS,
  SET_PROFILE_REQUEST_ERROR,
  SET_SYNTHETIC_BREADCRUMB_ITEMS,
  ADD_NOTIFICATION_POOL,
  REMOVE_NOTIFICATION_POOL,
  UPDATE_NOTIFICATION_POOL,
  SET_TAB_SUBMIT_BUTTON
} from './constants'

import Auth from '@modules/auth'
import { Resource, Collection } from '../rest-api'

export function setCounter(counter) {
  return {
    type: SET_COUNTER,
    payload: counter
  }
}

export function setAuthState(authState) {
  return {
    type: SET_AUTH_STATE,
    payload: authState
  }
}

export function logout(originUrl) {
  return dispatch => {
    dispatch(setAuthState(false))
    dispatch(setProfile({}))
    Auth.logout(originUrl)
  }
}

function setProfile(profile) {
  return {
    type: SET_PROFILE,
    payload: profile
  }
}

function setProfileRequestStatus(status) {
  return {
    type: SET_PROFILE_REQUEST_STATUS,
    payload: status
  }
}

function setProfileRequestError(error) {
  return {
    type: SET_PROFILE_REQUEST_ERROR,
    payload: error
  }
}

export function fetchProfile(cb) {
  return dispatch => {
    dispatch(setProfileRequestStatus(true))
    dispatch(setProfileRequestError(null))

    Auth.isoFetchProfile((err, profile) => {
      dispatch(setProfileRequestStatus(false))

      if (err) {
        cb ? cb(err) : dispatch(setProfileRequestError(err))
      }

      cb ? cb(null, profile) : dispatch(setProfile(profile))
    })
  }
}

// ViewAs Methods
export function setIsViewAsOpen(isViewAsOpen) {
  return {
    type: SET_IS_VIEW_AS_OPEN,
    payload: isViewAsOpen
  }
}

export function setIsViewAsSelected(isViewAsSelected) {
  return {
    type: SET_IS_VIEW_AS_SELECTED,
    payload: isViewAsSelected
  }
}

export function setViewingAs(viewingAs) {
  return {
    type: SET_VIEWING_AS,
    payload: viewingAs
  }
}

export function setImpersonation(isSelected, viewingAs) {
  return dispatch => {
    dispatch(setIsViewAsSelected(isSelected))
    dispatch(setViewingAs(viewingAs))
  }
}

// End View As Methods
function setCollectionRequestStatus(endpoint, status) {
  return {
    type: SET_COLLECTION_REQUEST_STATUS,
    payload: { endpoint, status }
  }
}

export function setCollectionRequestError(endpoint, error) {
  return {
    type: SET_COLLECTION_REQUEST_ERROR,
    payload: { endpoint, error }
  }
}

export function setCollection(endpoint, data) {
  return {
    type: SET_COLLECTION,
    payload: { endpoint, data }
  }
}

/**
 * Intention
 *    - Use this action to fetch a REST collection.
 *    - To use this as a stateless action, pass in a callback.
 * @param  {String}   [endpoint] The collection endpoint.
 * @param  {Object}   [config={}] An API config object.
 * @param  {Function|null} cb Execution-exclusive callback.
 * @return {void}
 */
export function fetchCollection(endpoint, config = {}, cb) {
  return dispatch => {
    dispatch(setCollectionRequestStatus(endpoint, true))
    dispatch(setCollectionRequestError(endpoint, null))

    const collection = new Collection(endpoint, config.apiVersion)

    collection.readSigned(config, (err, res) => {
      dispatch(setCollectionRequestStatus(endpoint, false))

      if (err) {
        cb ? cb(err) : dispatch(setCollectionRequestError(endpoint, err))
        return
      }
      cb ? cb(null, res.data) : dispatch(setCollection(endpoint, res.data))
    })
  }
}

function setResourceRequestStatus(endpoint, status) {
  return {
    type: SET_RESOURCE_REQUEST_STATUS,
    payload: { endpoint, status }
  }
}

function setResourceRequestError(endpoint, error) {
  return {
    type: SET_RESOURCE_REQUEST_ERROR,
    payload: { endpoint, error }
  }
}

function setResource(endpoint, data) {
  return {
    type: SET_RESOURCE,
    payload: { endpoint, data }
  }
}

/**
 * Intention
 *    - Use this action to fetch a REST resource.
 *    - To use this as a stateless action, pass in a callback.
 * @param  {String}   [endpoint] The resource endpoint.
 * @param  {String}   [id] The resource id.
 * @param  {Object}   [params={}] An API config object.
 * @param  {Function|null} cb Execution-exclusive callback.
 * @return {void}
 */
export function fetchResource(endpoint, id, config = {}, cb) {
  return dispatch => {
    dispatch(setResourceRequestStatus(endpoint, true))
    dispatch(setResourceRequestError(endpoint, null))

    const resource = new Resource(endpoint, config.apiVersion)

    resource.readSigned(
      id,
      {
        ...config
      },
      (err, res) => {
        dispatch(setResourceRequestStatus(endpoint, false))

        if (err) {
          cb ? cb(err) : dispatch(setResourceRequestError(endpoint, err))
          return
        }

        cb ? cb(null, res) : dispatch(setResource(endpoint, res.data))
      }
    )
  }
}

export function setMobileMenu(mobileMenu) {
  return {
    type: SET_MOBILE_MENU,
    payload: mobileMenu
  }
}

export function setLocation(location) {
  return {
    type: SET_LOCATION,
    payload: location
  }
}

export function setSyntheticBreadcrumbItems(syntheticBreadcrumbItems) {
  return {
    type: SET_SYNTHETIC_BREADCRUMB_ITEMS,
    payload: syntheticBreadcrumbItems
  }
}

export function addNotification(notification) {
  return {
    type: ADD_NOTIFICATION_POOL,
    payload: notification
  }
}

export function removeNotification(id) {
  return {
    type: REMOVE_NOTIFICATION_POOL,
    payload: id
  }
}

export function updateNotification(id, data) {
  return {
    type: UPDATE_NOTIFICATION_POOL,
    payload: { id, data }
  }
}

export function setTabSubmitButton(ref) {
  return {
    type: SET_TAB_SUBMIT_BUTTON,
    payload: ref
  }
}
