import TextField from './components/TextField'
import WithIcon from './components/WithIcon'
import DatePicker from './components/DatePicker'
import TimePicker from './components/TimePicker'
import DateRange from './components/DateRange'
import Labeled from './components/Labeled'
import CountryPhone from './components/CountryPhone'

TextField.WithIcon = WithIcon
TextField.DatePicker = DatePicker
TextField.TimePicker = TimePicker
TextField.DateRange = DateRange
TextField.Labeled = Labeled
TextField.CountryPhone = CountryPhone

export default TextField
