import React from 'react'
import cx from 'classnames'
import { func, arrayOf, shape, string, bool, element, node } from 'prop-types'

// Components
import { Header } from '@components/Mobile'

// Modules
import * as models from '@modules/models'
import { noAuthLinks, moderatorLinks } from '../../modules/utils'

// Hooks
import useIsModerator from '@hooks/useIsModerator'

// Styles
import styles from './_.module.scss'

const Mobile = ({
  menuLinks,
  onMobileClickGoto,
  myprofileUrl,
  searchUrl,
  notificationsUrl,
  current,
  children,
  profile,
  onBackClick,
  internalHeader,
  isInternalPage,
  title
}) => {
  const isModerator = useIsModerator()
  const allUsersLinks = noAuthLinks(menuLinks)
  const filteredMenuLinks = isModerator ? [...allUsersLinks, ...moderatorLinks(menuLinks)] : allUsersLinks


  return (
  <div className="di-n-tp di-f-tpo">
    <div
      className={cx(
        'di-f',
        'baco-primary-3',
        'alit-c',
        'juco-sb',
        styles.mobileTopNav,
        {
          'pax-2': !isInternalPage
        }
      )}
    >
      {isInternalPage ? (
        internalHeader || (
          <Header.InternalNav onBackClick={onBackClick} title={title} />
        )
      ) : (
        <Header.DefaultNav
          onMobileClickGoto={onMobileClickGoto}
          myprofileUrl={myprofileUrl}
          notificationsUrl={notificationsUrl}
          searchUrl={searchUrl}
          profile={profile}
        />
      )}
    </div>
    <div
      className={cx(
        'po-f',
        'di-f',
        'fldi-r',
        'baco-primary-3',
        'pay-1',
        styles.mobileBottomNav
      )}
    >
      {children(filteredMenuLinks, current, true)}
    </div>
  </div>
)}

Mobile.propTypes = {
  children: func,
  current: string,
  internalHeader: element,
  menuLinks: arrayOf(shape(models.NavItem)),
  myprofileUrl: string,
  searchUrl: string,
  notificationsUrl: string,
  profile: shape(models.Profile),
  isInternalPage: bool,
  title: node,
  onBackClick: func,
  onMobileClickGoto: func
}

Mobile.defaultProps = {
  children: () => null,
  current: '',
  internalHeader: null,
  menuLinks: [],
  myprofileUrl: '',
  searchUrl: '',
  notificationsUrl: '',
  profile: {},
  isInternalPage: false,
  title: '',
  onBackClick: () => null,
  onMobileClickGoto: () => null
}

export default Mobile
