import { string, array } from 'prop-types'

export const Profile = {
  name: string,
  nickname: string,
  picture: string,
  given_name: string,
  family_name: string
}

export const NavItem = {
  url: string,
  text: string,
  icon: string,
  mobileIcon: string,
  submenu: array
}
