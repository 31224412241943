import React from 'react'
import { arrayOf, shape, element, string, node, bool } from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import cx from 'classnames'
import { Location } from '@reach/router'

// modules
import { setLocation } from '@modules/redux/actions'
import store from '@modules/redux/store'

// Components
import ImpersonationStatusBar from '@compositions/ImpersonationStatusBar'
import Toast from '@compositions/Toast'
import FeatureToggle from '@components/FeatureToggle'

import Footer from './components/Footer'
import Header from './components/Header'

// styles
import '@styles/entry.scss'
import styles from './_.module.scss'

// hoc
import withTogglesConnect from '@hoc/withTogglesConnect'

const Layout = ({
  children,
  location,
  className,
  breadcrumbs,
  internalHeader,
  topComponent,
  flagName,
  showHeader,
  ...rest
}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            gatsbyAuth0Domain
          }
        }
      }
    `}
    render={data => {
      return (
        <>
          <ImpersonationStatusBar />
          <div className={cx('di-f', 'fldi-c', styles.layout)}>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                {
                  name: 'viewport',
                  content:
                    'width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1, shrink-to-fit=no'
                }
              ]}
            >
              <html lang="en" />
            </Helmet>

            <Location>
              {({ location }) => {
                store.dispatch(setLocation(location))
              }}
            </Location>

            {flagName ? (
              <FeatureToggle.With404Fallback name={flagName} />
            ) : null}

            {showHeader && (
              <Header breadcrumbs={breadcrumbs} internalHeader={internalHeader}>
                {topComponent}
              </Header>
            )}
            <main
              className={cx(
                'di-f',
                'fldi-c',
                'juco-s',
                'alco-c',
                'may-4',
                styles.main,
                className
              )}
              {...rest}
            >
              {children}
            </main>

            <Footer className="mat-4" />

            <Toast />
          </div>
        </>
      )
    }}
  />
)

Layout.propTypes = {
  breadcrumbs: arrayOf(shape({})),
  children: node,
  className: string,
  internalHeader: element,
  topComponent: node,
  location: string,
  flagName: string,
  showHeader: bool
}

Layout.defaultProps = {
  className: '',
  children: null,
  breadcrumbs: [],
  internalHeader: null,
  topComponent: null,
  location: '/',
  flagName: null,
  showHeader: true
}

export default withTogglesConnect(Layout)
