import React from 'react'

export default class Highlight {
  static matchesInString(text, query) {
    const reg = '(' + query.split(' ').join('|') + ')(?![^<]*>|[^<>]*</)'
    const regex = new RegExp(reg, 'gi')
    return text.split(' ').map(part => {
      const matched = part.match(regex)
      if (matched) {
        const textChanged = matched[0]
        return (
          <>
            <span className="co-primary-3">{textChanged}</span>
            {part.replace(textChanged, '')}
          </>
        )
      }
      return part
    })
  }
  static results(search, dataset) {
    return dataset.map(item => {
      const { lead, name } = item || {}

      const { contact: contactData, resident: residentData } = lead || {}

      const contact = {
        ...contactData,
        firstName: contactData
          ? Highlight.matchesInString(contactData.firstName, search)
          : '',
        lastName: contactData
          ? Highlight.matchesInString(contactData.lastName, search)
          : ''
      }
      const resident = {
        ...residentData,
        firstName: residentData
          ? Highlight.matchesInString(residentData.firstName, search)
          : '',
        lastName: residentData
          ? Highlight.matchesInString(residentData.lastName, search)
          : ''
      }

      const communityName = name ? Highlight.matchesInString(name, search) : ''
      
      if (lead) {
        return {
          ...item,
          lead: {
            ...lead,
            contact,
            resident
          }
        }
      } else if (name) {
        return {
          ...item,
          name: communityName
        }
      }
      return item
    })
  }
}
