import { withFormsy } from 'formsy-react'
import { func, string, bool, any, number, object, node } from 'prop-types'
import cx from 'classnames'
import { compose } from 'redux'

import React, { Component } from 'react'

// styles
import styles from './_.module.scss'

// hoc
import withPalette from '@hoc/withPalette'

// modules
import { THEMES } from '@components/constants'

// helpers
const handleChange = (value, applyMask, cb) => {
  cb(applyMask(value))
}

export class TextField extends Component {
  render() {
    const {
      getValue,
      setValue,
      placeholder,
      name,
      className,
      textFieldClassName,
      disabled,
      palette,
      autoComplete,
      pattern,
      validations,
      onChange,
      onFocus,
      type,
      attrs,
      onBlur,
      applyMask,
      maxLength,
      withIcon,
      icon,
      errorMessage,
      Constructor,
      isFormsy,
      readOnly
    } = this.props

    const { value, ...restAttrs } = attrs

    return (
      <div
        className={cx(
          'di-f',
          'fldi-c',
          className,
          styles[palette],
          styles.textFieldContainer,
          {
            [styles.disabled]: disabled,
            [styles.withError]: !!errorMessage
          }
        )}
      >
        <Constructor
          className={cx(
            'pa-2',
            'bora',
            'bost-s',
            'fosi-2',
            'bowi-1',
            textFieldClassName,
            styles.textField
          )}
          type={type}
          name={name}
          onChange={event => {
            if (event && event.currentTarget) {
              handleChange(event.currentTarget.value, applyMask, setValue)
            } else {
              handleChange(event, applyMask, setValue)
            }

            onChange(event)
          }}
          onFocus={event => onFocus(event)}
          onBlur={event => onBlur(event, setValue)}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete={autoComplete}
          pattern={pattern}
          validations={validations}
          maxLength={maxLength}
          value={isFormsy ? getValue() || '' : value}
          readOnly={readOnly}
          {...restAttrs}
        />
        {withIcon && icon}
        <div
          className={cx(
            'fosi-1',
            'teal-l',
            'pax-3',
            'pay-1',
            styles.errorMessage
          )}
        >
          {errorMessage}
        </div>
      </div>
    )
  }
}

TextField.propTypes = {
  name: string.isRequired,
  applyMask: func,
  attrs: object,
  autoComplete: string,
  className: string,
  Constructor: func,
  disabled: bool,
  errorMessage: string,
  getValue: func,
  icon: node,
  isFormsy: bool,
  maxLength: number,
  palette: string,
  pattern: string,
  placeholder: string,
  setValue: func,
  textFieldClassName: string,
  type: string,
  validations: any,
  withIcon: bool,
  readOnly: bool,
  onBlur: func,
  onChange: func,
  onFocus: func
}

TextField.defaultProps = {
  className: '',
  textFieldClassName: '',
  getValue: () => null,
  placeholder: '',
  setValue: () => null,
  disabled: false,
  palette: THEMES.primary,
  autoComplete: 'on',
  pattern: null,
  validations: '',
  maxLength: null,
  onChange: () => null,
  onFocus: () => null,
  type: 'text',
  attrs: {},
  onBlur: () => null,
  withIcon: false,
  readOnly: false,
  icon: null,
  errorMessage: '',
  applyMask: value => value,
  Constructor: props => <input {...props} />,
  isFormsy: true
}

export default compose(
  withPalette,
  withFormsy
)(TextField)
