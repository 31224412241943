import React from 'react'
import { any, string, number, object } from 'prop-types'
import cx from 'classnames'

// hoc
import withElevation from '@hoc/withElevation'

const Card = ({ className, children, elevation, style }) => (
  <div className={cx('bora', `bosh-${elevation}`, className)} style={style}>
    {children}
  </div>
)

Card.propTypes = {
  children: any,
  className: string,
  elevation: number,
  style: object
}

Card.defaultProps = {
  children: null,
  className: '',
  elevation: 1,
  style: {}
}

export default withElevation(Card)
