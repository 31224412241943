import React from 'react'
import cx from 'classnames'
import { func, string } from 'prop-types'

import Icon from '@components/Icon'

const SearchIcon = ({ onMobileSearchClick, className }) => {
  return (
    <div
      className={cx(
        'di-f',
        'alit-c',
        'juco-fe',
        'co-white',
        'mar-1',
        className
      )}
      role="button"
      aria-labelledby="nav-search-accessibility"
      onClick={onMobileSearchClick}
    >
      <Icon name="search" size={17} />
      <span className="di-n" id="nav-search-accessibility">
        Search
      </span>
    </div>
  )
}

SearchIcon.propTypes = {
  className: string,
  onMobileSearchClick: func
}

SearchIcon.defaultProps = {
  className: '',
  onMobileSearchClick: () => null
}

export default SearchIcon
