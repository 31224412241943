import React from 'react'
import { node, string, number, oneOfType, oneOf, object } from 'prop-types'
import cx from 'classnames'

import { FLOAT_LEFT, FLOAT_RIGHT } from '../../constants'

const Sidebar = ({ children, className, float, width, style, ...rest }) => (
  <div
    className={cx(
      float === FLOAT_LEFT ? 'or-1 mar-2' : 'or-2 mal-2',
      className
    )}
    style={{ width, ...style }}
    {...rest}
  >
    {children}
  </div>
)

Sidebar.propTypes = {
  children: node,
  className: string,
  style: object,
  float: oneOf([FLOAT_LEFT, FLOAT_RIGHT]),
  width: oneOfType([number, string])
}

Sidebar.defaultProps = {
  className: '',
  float: FLOAT_LEFT,
  style: {},
  width: 100,
  children: null
}

export default Sidebar
