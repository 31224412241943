import React, { Component } from 'react'
import cx from 'classnames'
import { node, bool, func, string } from 'prop-types'

// Components
import { THEMES } from '@components/constants'

// styles
import styles from './_.module.scss'

// modules
import DOM from '@modules/dom'
import Env from '@modules/env'

// HOC
import withPalette from '@hoc/withPalette'

/**
 * Function addTabContentActiveClass: Add active class to a DOMElement
 * if it doesn't contain it
 * @param  {DOMElement} element
 * @param  {string} activeClassName
 */
const addTabContentActiveClass = (element, activeClassName) => {
  !DOM.hasClass(element.classList, activeClassName) &&
    DOM.addClass(element.classList, activeClassName)
}
/**
 * Function removeTabContentActiveClass: Remove active class from a DOMElement
 * if it contains it
 * @param  {DOMElement} element
 * @param  {string} activeClassName
 */
const removeTabContentActiveClass = (element, activeClassName) => {
  DOM.hasClass(element.classList, activeClassName) &&
    DOM.removeClass(element.classList, activeClassName)
}
/**
 * Function changeActiveTab: Switch to active (`activeClassName`) the element
 * found by the `targetLink` and hide all elements found by the `className` parameter
 * @param  {string} targetLink
 * @param  {string} className
 * @param  {string} activeClassName
 */
const changeActiveTab = (targetLink, className, activeClassName, siblingId) => {

  const element = document.querySelector(`#${siblingId}`);
  if(!element) return false
  let siblings = DOM.getSiblings(element);

  Array.prototype.forEach.call(siblings, sibling => {

    if (`#${sibling.id}` !== targetLink) {
      removeTabContentActiveClass(sibling, activeClassName)
    }
   
  })
  const id = targetLink.replace('#', '')
  const toActivateElem = Env.getDocument().getElementById(id)
  if (toActivateElem) {
    addTabContentActiveClass(toActivateElem, activeClassName)
  }
}

const STATE_ACTIVE_TAB = 'activeTab'
export class Tabs extends Component {
  constructor(props) {
    super(props)
    const { initialTabActive } = props
    this.state = {
      [STATE_ACTIVE_TAB]: initialTabActive
    }
  }

  goToTab(link) {
    const { onTabChange, id } = this.props

    this.setState({
      [STATE_ACTIVE_TAB]: link
    })

    changeActiveTab(link, styles.tabContent, styles.activeTabContent, id)

    onTabChange(link)
  }

  render() {
    const {
      children,
      isPhoneOnly,
      isDesktopOnly,
      className,
      isFill,
      palette,
      id
    } = this.props
    const activeTab = this.state[STATE_ACTIVE_TAB]
    return (
      <ul
        id={id}
        className={cx(
          'fldi-r',
          'pa-0',
          'ma-0',
          isFill ? `baco-${palette}-3` : 'baco-white',
          isFill ? `co-${palette}-1` : 'co-gray-3',
          'juco-sb',
          isPhoneOnly
            ? 'di-f-po di-n-p'
            : isDesktopOnly
            ? 'di-f-p di-n-po'
            : 'di-f',
          className
        )}
      >
        {children &&
          children.length > 0 &&
          children.map((item, key) => {
            if(!item) return null
            const { type: ChildComponent, props } = item
            const { children: itemChildren, link, onClick } = props
            return (
              (ChildComponent && (
                <ChildComponent
                  {...props}
                  isActive={activeTab === link}
                  onClick={e => {
                    e.preventDefault()
                    this.goToTab(link)
                    // Trigger Single Tab Change event
                    onClick && onClick(link)
                  }}
                  key={key}
                  palette={palette}
                  isFill={isFill}
                >
                  {itemChildren}
                </ChildComponent>
              )) ||
              ''
            )
          })}
      </ul>
    )
  }
}

Tabs.propTypes = {
  id: string,
  children: node,
  className: string,
  isFill: bool,
  initialTabActive: string,
  isPhoneOnly: bool,
  isDesktopOnly: bool,
  palette: string,
  onTabChange: func
}

Tabs.defaultProps = {
  children: null,
  className: null,
  isFill: false,
  initialTabActive: '',
  isPhoneOnly: false,
  isDesktopOnly: false,
  palette: THEMES.primary,
  onTabChange: () => null,
  id: null
}

Tabs.tabContentClass = styles.tabContent
Tabs.activeTabContent = styles.activeTabContent
Tabs.phoneOnly = styles.phoneOnly
Tabs.desktopOnly = styles.desktopOnly

export default withPalette(Tabs)
