import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

// components
import TextField from '../TextField'

// styles
import '../../styles/overrides.scss'

const TextFieldDatePicker = props => (
  <TextField Constructor={DatePicker} {...props} isFormsy={false} />
)

export default TextFieldDatePicker
