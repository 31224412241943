import { Collection, Resource } from '@modules/rest-api'
import { REST_API_ENDPOINTS, API_VERSION } from '@modules/constants'

export default class Cloudinary {
  static makeThumbnailFromSecureUrl(secureUrl, width = 300) {
    let tokens = secureUrl.split('/')
    tokens.splice(-4, 0, `w_${width},c_scale`)
    return tokens.join('/')
  }

  static uploadSigned(data, config, cb = () => null) {
    const image = new Collection(
      REST_API_ENDPOINTS.communityAssets,
      API_VERSION
    )
    image.createSignedFile(data, config, cb)
  }

  static readLogoSigned(id, config = {}, cb = () => null) {
    const configParams = config.params || {}
    const params = {
      ...configParams,
      communityIds: id,
      moderationStatusFlags: 2,
      typeFlags: 16,
      itemsPerPage: 500
    }
    const image = new Collection(
      REST_API_ENDPOINTS.communityAssets,
      API_VERSION
    )
    image.readSigned({ ...config, params }, cb)
  }

  static deleteSigned(id = '', config, cb = () => null) {
    const image = new Resource(REST_API_ENDPOINTS.communityAssets, API_VERSION)
    image.deleteSigned(id, config, cb)
  }

  static tranformImage(url, transformation = 'w_300,h_200') {
    return url.replace(
      'image/upload/',
      `image/upload/${transformation ? `${transformation}/` : ''}`
    )
  }
}
