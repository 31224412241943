import React, { useState } from 'react'
import cx from 'classnames'
import { any, func, string, bool } from 'prop-types'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates'
import moment from 'moment'

// styles
import '../../styles/overrides.scss'
import styles from './_.module.scss'

const TextFieldDatePicker = props => {
  const [focusedInput, setFocusedInput] = useState()
  const [startDate, setStartDate] = useState(props.startDate ? moment(props.startDate) : null)
  const [endDate, setEndDate] = useState(props.endDate ? moment(props.endDate) : null)

  return (
    <>
      <span className={cx("mab-1", styles.label)}>{props.label}</span>

      <DateRangePicker
        startDateId={props.startDateId}
        endDateId={props.endDateId}
        onDatesChange={dates => {
          setStartDate(dates.startDate)
          setEndDate(dates.endDate)

          const dateFormat = 'YYYY/MM/DD'
          const datesWithFormat = {
            startDate: dates.startDate ? dates.startDate.format(`${dateFormat} 00:00:00`) : null,
            endDate: dates.endDate ? dates.endDate.format(`${dateFormat} 23:59:59`) : null
          }
          if(props.returnPartials || (dates.startDate && dates.endDate)) {
            props.onChange(datesWithFormat.startDate, datesWithFormat.endDate)
          } else if(props.returnPartials) {
            props.onChange(datesWithFormat.startDate, datesWithFormat.endDate)
          }
        }}
        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
        startDate={startDate}        
        endDate={endDate}
        focusedInput={focusedInput}
        maxDate={moment()}
        isOutsideRange={day => {
          let today = moment()
          let oneYearAfterStartDate = moment(startDate).add(1, 'year')
          let maxFutureDate = today.isSameOrAfter(oneYearAfterStartDate) ? oneYearAfterStartDate : today
          let isDayBeforeFutureDate = isInclusivelyBeforeDay(day, maxFutureDate)

          return !isDayBeforeFutureDate
        }}
        initialVisibleMonth={() => startDate || moment().subtract(1, 'month')}
        hideKeyboardShortcutsPanel={true}
        showClearDates={true}
        reopenPickerOnClearDates={props.reopenPickerOnClearDates}
      />
    </>
  );
}

TextFieldDatePicker.propTypes = {
  startDateId: string,
  endDateId: string,
  startDate: any,
  endDate: any,
  label: string,
  returnPartials: bool,
  reopenPickerOnClearDates: bool,
  onChange: func
}

TextFieldDatePicker.defaultProps = {
  startDateId: null,
  endDateId: null,
  onChange: () => null,
  label: '',
  startDate: null,
  endDate: null,
  returnPartials: false,
  reopenPickerOnClearDates: true
}

export default TextFieldDatePicker
