import React from 'react'
import { arrayOf, shape, string, bool, oneOf } from 'prop-types'
import cx from 'classnames'

// Components
import List from '../List'

// Models
import * as models from '@modules/models'

// Styles
import styles from './_.module.scss'

const DIRECTION_ROW = 'row'
const DIRECTION_COLUMN = 'column'

const Menu = ({ list, current, isMobileOnly, direction, className }) => (
  <ul
    className={cx(
      'di-f',
      direction == DIRECTION_ROW ? 'fldi-r' : 'fldi-c',
      direction == DIRECTION_ROW ? 'alit-c' : '',
      isMobileOnly ? styles.mobileMenu : '',
      className
    )}
  >
    <List list={list} current={current} isMobileOnly={isMobileOnly} />
  </ul>
)

Menu.propTypes = {
  list: arrayOf(shape(models.NavItem)),
  current: string,
  isMobileOnly: bool,
  className: string,
  direction: oneOf([DIRECTION_ROW, DIRECTION_COLUMN])
}

Menu.defaultProps = {
  list: [],
  current: '',
  isMobileOnly: false,
  className: '',
  direction: DIRECTION_ROW
}

Menu.DIRECTION_COLUMN = DIRECTION_COLUMN
Menu.DIRECTION_ROW = DIRECTION_ROW

export default Menu
