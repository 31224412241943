import React from 'react'
import cx from 'classnames'
import { any, number, string, oneOfType } from 'prop-types'

// styles
import styles from './_.module.scss'

const Chart = ({ children, size }) => (
  <figure
    style={{ width: size, height: size }}
    className={cx(styles.chart, 'po-r')}
  >
    {children}
  </figure>
)

Chart.propTypes = {
  size: oneOfType([number, string]),
  children: any
}
Chart.defaultProps = {
  size: 40,
  children: null
}

export default Chart
