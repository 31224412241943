import cuid from 'cuid'
import Env from '../env'
import EventListener from '../event-listener'

export default class DOM {
  static getUniqueId = () => cuid()

  static hasClass = (classList = {}, className) => classList.contains(className)

  static addClass = (classList = {}, className) => {
    if (Env.isClient()) {
      !DOM.hasClass(classList, className) && classList.add(className)
    }
  }

  static removeClass = (classList = {}, className) => {
    if (Env.isClient()) {
      DOM.hasClass(classList, className) && classList.remove(className)
    }
  }

  static addMultipleEventListeners = (domElement, eventNames = [], handler) => {
    if (Env.isClient() && domElement && handler) {
      for (let eventName of eventNames) {
        domElement.addEventListener(eventName, handler)
      }
    }
  }

  static removeMultipleEventListeners = (
    domElement,
    eventNames = [],
    handler
  ) => {
    if (Env.isClient() && domElement && handler) {
      for (let eventName of eventNames) {
        domElement.removeEventListener(eventName, handler)
      }
    }
  }

  static accessibilityClick(event, cb = () => null) {
    if (EventListener.isClick(event) || EventListener.isSpace(event)) {
      event.preventDefault()
      event.stopPropagation()
      cb(true)
    } else {
      cb(false)
    }
  }

  static getSiblings = function(elem) {
    let siblings = []
    let sibling = elem.parentNode.firstChild

    while (sibling) {
      if (sibling.nodeType === 1 && sibling !== elem) {
        siblings.push(sibling)
      }
      sibling = sibling.nextSibling
    }
    return siblings
  }
}
