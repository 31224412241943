import React, { Component } from 'react'
import { func, string, any, bool, object } from 'prop-types'
import cx from 'classnames'

// components
import EventOutsideDetector from '@components/EventOutsideDetector'
import DropdownContentWrapper from '@components/DropdownContentWrapper'

// styles
import styles from './_.module.scss'

const HandlerWrapper = ({ onClick, className, children, isOpen }) => (
  <div onClick={onClick} className={className}>
    {children(isOpen)}
  </div>
)

HandlerWrapper.propTypes = {
  children: func,
  className: string,
  isOpen: bool,
  onClick: func
}

HandlerWrapper.defaultProps = {
  children: () => null,
  className: '',
  isOpen: false,
  onClick: () => null
}

const STATE_IS_OPEN = 'isOpen'

export default class Dropdown extends Component {
  state = {
    [STATE_IS_OPEN]: this.props.isOpen
  }

  render() {
    const {
      handler,
      children,
      className,
      hideOnEventName,
      onEventOutside,
      contentWrapperClassName,
      contentWrapperStyle,
      cardStyle
    } = this.props
    const isOpen = this.state[STATE_IS_OPEN]
    const listenEventNames = hideOnEventName || 'mousedown,mousemove'
    return (
      <EventOutsideDetector
        eventName={listenEventNames}
        onEvent={() => {
          this.setState(
            { [STATE_IS_OPEN]: false },
            () => isOpen && onEventOutside()
          )
        }}
      >
        <div className={cx('po-r', className)}>
          <HandlerWrapper
            onClick={() => {
              this.setState({ [STATE_IS_OPEN]: !isOpen })
              isOpen && onEventOutside()
            }}
            isOpen={isOpen}
          >
            {handler}
          </HandlerWrapper>

          <DropdownContentWrapper
            style={contentWrapperStyle}
            className={cx(contentWrapperClassName, styles.contentWrapper, {
              'di-n': !isOpen
            })}
            cardStyle={cardStyle}
          >
            {typeof children === 'function'
              ? children(isOpen => this.setState({ [STATE_IS_OPEN]: isOpen }))
              : children}
          </DropdownContentWrapper>
        </div>
      </EventOutsideDetector>
    )
  }
}

Dropdown.propTypes = {
  handler: func.isRequired,
  cardStyle: object,
  children: any,
  className: string,
  contentWrapperClassName: string,
  contentWrapperStyle: object,
  hideOnEventName: string,
  isOpen: bool,
  onEventOutside: func
}

Dropdown.defaultProps = {
  children: null,
  className: '',
  contentWrapperClassName: '',
  isOpen: false,
  hideOnEventName: '',
  onEventOutside: () => null,
  contentWrapperStyle: {},
  cardStyle: {}
}
