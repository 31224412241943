import React from 'react'
import { string, bool, func, object, node } from 'prop-types'
import InputMask from 'react-input-mask';
import cx from 'classnames'
import { withFormsy } from 'formsy-react'
import { compose } from 'redux'

// styles
import styles from './_.module.scss'

// hoc
import withPalette from '@hoc/withPalette'

// modules
import { THEMES } from '@components/constants'

const MaskedTextField = ({
    hasErrorMessage,
    disabled,
    palette,
    type,
    className,
    textFieldClassName,
    isFormsy,
    getValue,
    setValue,
    onChange,
    onBlur,
    onFocus,
    mask,
    attrs,
    withIcon,
    icon,
    name,
    placeholder
}) => {
  const { value, ...restAttrs } = attrs
  return (
    <div
        className={cx(
            'di-f',
            'fldi-c',
            className,
            styles[palette],
            styles.textFieldContainer,
            {
                [styles.disabled]: disabled,
                [styles.withError]: !!hasErrorMessage
            }
        )}
    >
        <InputMask
            className={cx(
                'pa-2',
                'bora',
                'bost-s',
                'fosi-2',
                'bowi-1',
                textFieldClassName,
                styles.textField
            )}
            mask={mask}
            maskChar=''
            type={type}
            name={name}
            value={isFormsy ? getValue() || '' : value}
            onChange={event => {
                if (event && event.currentTarget) {
                    setValue(event.currentTarget.value)
                } else {
                    setValue(event)
                }
    
                onChange(event)
            }}
            onFocus={onFocus}
            onBlur={event => onBlur(event, setValue)}
            disabled={disabled}
            placeholder={placeholder}
            {...restAttrs}
        />
        {withIcon && icon}
        <div
            className={cx(
            'fosi-1',
            'teal-l',
            'pax-3',
            'pay-1',
            styles.errorMessage
        )}
        >
            {hasErrorMessage}
        </div>
    </div>
  )
}

MaskedTextField.propTypes = {
    name: string.isRequired,
    hasErrorMessage: string,
    disabled: bool,
    palette: string,
    textFieldClassName: string,
    type: string,
    isFormsy: bool,
    getValue: func,
    setValue: func,
    attrs: object,
    withIcon: bool,
    placeholder: string,
    icon: node,
    mask: string,
    value: string,
    className: string,
    onChange: func,
    onBlur: func,
    onFocus: func
}

MaskedTextField.defaultProps = {
    hasErrorMessage: null,
    disabled: false,
    textFieldClassName: '',
    palette: THEMES.primary,
    type: 'text',
    isFormsy: true,
    attrs: {},
    mask: '',
    value: '',
    placeholder: '',
    withIcon: false,
    icon: null,
    getValue: () => null,
    setValue: () => null,
    className: '',
    onChange: () => null,
    onBlur: () => null,
    onFocus: () => null
}

export default compose(
    withPalette,
    withFormsy
)(MaskedTextField)