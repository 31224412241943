import React from 'react'
import cx from 'classnames'
import { any, string } from 'prop-types'

// styles
import styles from './_.module.scss'

const ContentWrapper = ({ className, children, ...rest }) => (
  <div {...rest} className={cx(styles.contentWrapper, className)}>
    {children}
  </div>
)

ContentWrapper.propTypes = {
  className: string,
  children: any
}

ContentWrapper.defaultProps = {
  className: '',
  children: null
}

export default ContentWrapper
