import {
  ADMIN_ROLE,
  MODERATOR_ROLE,
  IMPERSONATOR_ROLE
} from '@modules/constants'

export const noAuthLinks = links => links.filter(link => !link.roles)
export const adminLinks = links =>
  links.filter(
    link => link.roles && link.roles.some(role => role === ADMIN_ROLE)
  )
export const impersonatorLinks = links =>
  links.filter(
    link => link.roles && link.roles.some(role => role === IMPERSONATOR_ROLE)
  )
export const moderatorLinks = links =>
  links.filter(
    link => link.roles && link.roles.some(role => role === MODERATOR_ROLE)
  )
