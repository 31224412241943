import React, { Component } from 'react'
import { withLDProvider } from 'launchdarkly-react-client-sdk'

export default function(WrappedComponent) {
  const WrappedComponentWithToggles = withLDProvider(
    {
      clientSideID: process.env['GATSBY_LAUNCH_DARKLY_CLIENT_SIDE_ID'],
      options: { streaming: true }
    }
  )(WrappedComponent)

  return class extends Component {
    render() {
      return <WrappedComponentWithToggles {...this.props} />
    }
  }
}
