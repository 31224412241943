import React from 'react'
import { shape, string, bool, array } from 'prop-types'
import cx from 'classnames'

// hoc
import asTogglesConsumer from '@hoc/asTogglesConsumer'

// components
import TextLink from '@components/TextLink'
import Icon from '@components/Icon'
import { LOGOUT_URL } from '@components/constants'
import Subnav from '../Subnav'

// modules
import * as models from '@modules/models'
import Auth from '@modules/auth'
import Env from '@modules/env'

// styles
import styles from './_.module.scss'

const Link = ({ flag, isActive, isSubnav, isMobileOnly, item }) => {
  const DecoratedTextLink = () => (
    <TextLink
      className={cx('pa-2', isMobileOnly ? 'fosi-1 teal-c' : 'fosi-2')}
      to={flag ? item.url : (item.legacyUrl ? item.legacyUrl : item.url)}
      target={flag ? "_self" : (item.legacyUrl ? "_blank" : "_self")}
      onClick={() => {
        item.url === LOGOUT_URL && Auth.updateAuthForwardUrl(Env.getWindow().location)
      }}
    >
      <span
        className={
          item.url === LOGOUT_URL
            ? 'co-orange-3'
            : isActive
            ? 'co-white'
            : isSubnav
            ? 'co-gray-4'
            : 'co-primary-1'
        }
      >
        {isMobileOnly && <Icon name={item.mobileIcon} size={20} />}
        {item.text}
      </span>
    </TextLink>
  )

  return item.isStrictFlag ? (flag ? <DecoratedTextLink/> : null) : <DecoratedTextLink/>
}

Link.propTypes = {
  item: shape(models.NavItem).isRequired,
  isActive: bool,
  isSubnav: bool,
  isMobileOnly: bool,
  flag: bool
}

Link.defaultProps = {
  isActive: false,
  isSubnav: false,
  isMobileOnly: false,
  flag: false
}

const List = ({ flags, list, current, isMobileOnly, className, isSubnav }) =>
  list.map((item, key) => {
    const hasSubmenu = item.submenu && item.submenu.length > 0
    const isActive = current === item.url
    const flag = flags[item.flag]

    return (
      <li
        key={key}
        className={cx('di-f', 'alit-c', styles.menuLink, className, 'po-r', {
          'fldi-c': isMobileOnly
        })}
      >
        {!isMobileOnly && hasSubmenu ? (
          <Subnav item={item} current={current} flag={flag} />
        ) : (
          <Link
            isActive={isActive}
            isSubnav={isSubnav}
            isMobileOnly={isMobileOnly}
            item={item}
            flag={flag}
          />
        )}
      </li>
    )
  })

List.propTypes = {
  list: array.isRequired,
  className: string,
  current: string,
  isMobileOnly: bool,
  isSubmenu: bool
}

List.defaultProps = {
  className: '',
  current: '',
  isMobileOnly: false,
  isSubmenu: false
}

export default asTogglesConsumer(List)
