import React, { Component } from 'react'
import { string, number } from 'prop-types'
import cx from 'classnames'

// modules
import { THEMES } from '@components/constants'

// components
import Chart from '../Chart'

// icons
import likeIconSvgRef from '@icons/like.svg'

// styles
import styles from './_.module.scss'

// globals
const circleX = 21
const circleY = circleX
const circleRadius = 15.91549430918954

const Ring = ({ theme, thickness, ...rest }) => (
  <circle
    className={cx(styles.donut, theme)}
    cx={circleX}
    cy={circleY}
    r={circleRadius}
    fill="transparent"
    stroke="silver"
    strokeWidth={thickness}
    {...rest}
  />
)

Ring.propTypes = {
  thickness: number.isRequired,
  theme: string
}

Ring.defaultProps = {
  theme: THEMES.primary
}

export default class Donut extends Component {
  render() {
    const { progress, thickness } = this.props
    const isComplete = progress >= 100

    return (
      <Chart {...this.props}>
        <svg width="100%" height="100%" viewBox="0 0 42 42">
          <circle
            cx={circleX}
            cy={circleY}
            r={circleRadius}
            fill={isComplete ? '#10C8BE' : '#fff'}
            strokeWidth={thickness}
          />

          <Ring stroke="#BFE7E4" thickness={thickness} />

          <Ring
            stroke="#10C8BE"
            strokeDasharray={`${progress} ${100 - progress}`}
            strokeDashoffset="25"
            thickness={thickness}
          />

          {isComplete ? (
            <image
              x="25%"
              y="25%"
              width="50%"
              height="50%"
              xlinkHref={likeIconSvgRef}
            />
          ) : (
            <g className={styles.text}>
              <text
                x="50%"
                y="50%"
                fill="#56585E"
                className={styles.textContents}
              >
                {progress}
              </text>
            </g>
          )}
        </svg>
      </Chart>
    )
  }
}

Donut.propTypes = {
  size: number,
  progress: number,
  thickness: number
}

Donut.defaultProps = {
  size: 50,
  progress: 50,
  thickness: 5
}
