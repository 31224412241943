import variablesFromSass from '@styles/core/_variables.scss'

export const cssVariables = variablesFromSass

export const THEMES = {
  primary: 'primary',
  secondary: 'secondary',
  accent: 'accent',
  teal: 'teal',
  blue: 'blue',
  gray: 'gray',
  orange: 'orange',
  star: 'star',
  white: 'white',
  flat: 'flat',
  red: 'red'
}

export const FEEDBACK_THEMES = {
  low: THEMES.primary,
  medium: THEMES.gray,
  high: THEMES.secondary,
  critical: THEMES.orange
}

export const COLOR_TENDENCIES = {
  shade: 's',
  tint: 't'
}

export const THEME_TENDENCIES = {
  shade: 'shade',
  tint: 'tint'
}

export const COLOR_LEVELS = [1, 2, 3, 4]

export const QA_PREFIXES = {
  default: 'qa-page__',
  leads: 'qa-leads-page__',
  leadsDetails: 'qa-leads-details-page__'
}

export const LOGOUT_URL = '/logout'

export const NOTIFICATIONS_THEMES = {
  notification_default: {
    color: 'black',
    palette: THEMES.gray
  },
  notification_info: {
    color: 'black',
    palette: THEMES.secondary
  },
  notification_success: {
    color: 'black',
    palette: THEMES.primary
  },
  notification_error: {
    color: 'black',
    palette: THEMES.secondary
  },
  notification_warning: {
    color: 'black',
    palette: THEMES.orange
  }
}

export const DATE_FORMAT = 'YYYY/MM/DD HH:mm:ss'
