import React from 'react'
import cx from 'classnames'
import { any, string, object } from 'prop-types'

// components
import Card from '@components/Card'

// styles
import styles from './_.module.scss'

const DropdownContentWrapper = ({
  className,
  children,
  style,
  cardClassName,
  cardStyle
}) => (
  <div
    className={cx('po-a', styles.dropdownContentWrapper, className)}
    style={style}
  >
    <Card
      elevation={2}
      className={cx('baco-white', cardClassName)}
      style={cardStyle}
    >
      {children}
    </Card>
  </div>
)

DropdownContentWrapper.propTypes = {
  children: any,
  className: string,
  style: object,
  cardClassName: string,
  cardStyle: object
}

DropdownContentWrapper.defaultProps = {
  children: null,
  className: '',
  isOpen: false,
  style: {},
  cardClassName: '',
  cardStyle: {}
}

export default DropdownContentWrapper
